.hi-score, .gene-score {
  height: $hi-score-width;
  width: $hi-score-height;
  display: block;
  float: left;
  border: $hi-score-border;
  margin-right: $base-inline-spacing;
  margin-left: $spacing-inline-small;
}

.hi-index {
  // These calculations came from method described in this article
  // https://css-tricks.com/css-variables-calc-rgb-enforcing-high-contrast-colors/
  --accessible-color: calc(
    (
        (
            ((var(--red) * 299) + (var(--green) * 587) + (var(--blue) * 114)) /
              1000
          ) - 128
      ) * -1000
  );
  line-height: 32px;
  padding: 5px;
  border-radius: 5px;
  background-color: rgb(var(--red), var(--green), var(--blue));
  color: rgb(
    var(--accessible-color),
    var(--accessible-color),
    var(--accessible-color)
  );
}
