.component {
  position: relative;
  min-width: calc(100vw - var(--sticky-to-left-scrollbar-width));

  &Sticky {
    position: sticky;
    left: 0;
    width: calc(100vw - var(--sticky-to-left-scrollbar-width));
  }
}
