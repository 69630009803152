$first-column-width: 132px;
$sv-presets-select-width: 36em;
$sv-presets-view-width: 2.5em;

#svFormFilter { // legacy code selector
  &.presets-loading {
    position: relative;

    .sv-presets-overlay {
      @include block-overlay;
      z-index: 2201; // to lap over jquery select z-index
    }
  }

  .sv-form-icons {
    width: $first-column-width;
  }
}

.sv-presets {
  display: grid;
  grid-template-columns: $first-column-width $sv-presets-select-width + $sv-presets-view-width auto;

  &-label {
    line-height: 40px;

    &-warning {
      display: inline;
      &.disabled {
        display: none;
      }
    }
  }

  &-select {
    width: $sv-presets-select-width;
    display: inline-block;

    // Needed to ensure the preset dropdown appears over the Chromosome view
    z-index: 2;
    position: relative;
  }

  &-view {
    margin-left: $spacing-x-small;
    display: inline;
    vertical-align: middle;

    .icon {
      font-size: 24px;
    }
  }
}
