/* Featurettes */

.featurette-divider {
  margin: 30px 0; /* Space out the Bootstrap <hr> more */
}

.featurette {
  overflow: hidden; /* Vertically center images part 2: clear their floats. */
  padding-bottom: 10px;
}

.featurette-heading {
  font-size: 50px;
  font-weight: 300px;
  line-height: 1;
  letter-spacing: -1px;
}

.featurette h3 {
  padding-bottom: 5px;
  padding-top: 10px;
}

.featurette h4 {
  margin-top: 10px;
}

/* Jumbotron */

.jumbotron {
  margin-top: 10px;
  margin-bottom: 0px;
  height: 300px;
}

/* CUSTOMIZE THE CAROUSEL
-------------------------------------------------- */

.carousel {
  top: 0px;
  margin-top: 0px;
  height: 300px;
  width: 1170px;
}

.carousel-inner {
  height: 300px;
  border-radius: 7px;
}

.carousel-inner a {
  width: 100px;
}

.carousel-control {
  height: 80px;
  margin-top: 0;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.7);
  background-color: transparent;
  border: 0;
  z-index: 10;
  opacity: 0.9;
}

.carousel-control:hover {
  color: #999999;
}

.carousel .item {
  height: 299px;
  width: 1169px;
}

.carousel .item .fa {
  font-size: 40px;
}

.carousel img {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 1170px;
  width: 1170px;
  height: 299px;
  max-width: 1170px;
}

.carousel-caption {
  background-color: transparent;
  z-index: 15;
  text-align: left;
  left: 10%;
  top: 0%;
}

.carousel-caption h1,
.carousel-caption .lead {
  margin: 0;
  line-height: 1.25;
  color: #fff;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.4);
}

.carousel-caption .btn {
  margin-top: 10px;
}

.carousel-indicators {
  bottom: -38px;
}

.carousel-indicators > li {
  background-color: #999999;
}

.carousel-indicators .active {
  background-color: #000000;
  /*border-color: #000000;*/
}

.carousel-control.left,
.carousel-control.right {
  background-image: none;
  margin-top: 135px;
}

/* Google Maps */

#map-canvas {
  height: 299px;
  width: 100%;
}

#map-canvas img {
  max-width: inherit;
}
