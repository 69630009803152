.hpo {
  &-terms {
    &-tree {
      padding: $spacing-medium 0 0 $spacing-medium;
      .glyphicon {
        color: $colour-black;
        font-size: $font-size-body-regular;
        padding-bottom: $spacing-x-small;
        margin-right: $spacing-small;
      }
    }
    &-code {
      color: $colour-grey-600;
    }
  }
}

.selected-hpo-terms {
  position: sticky;
  top: $spacing-medium;
  margin-top: -$spacing-x-small*0.5;
}
