.report-templates {
  &__checkbox {
    margin: 0;
  }

  &__label {
    // Needed to ensure checkboxes don't move when toggling the label on and off
    display: block;
  }
}
