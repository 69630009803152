// Colours
// $colour-{name of colour} where the name is taken from http://chir.ag/projects/name-that-color/

@import "./opacity";

$colour-azure-radiance: #007bff !default;
$colour-emerald: #4fd964;
$colour-blue-ribbon: #0072ff;
$colour-red: #f00;
$colour-roman: #d9534f;
$colour-sandy-brown: #f0ad4e;
$colour-green: #0f0;
$colour-fern: #5cb85c;
$colour-blue: #00f;
$colour-blue-cerulean: #2253a6;
$colour-cream: #ffc;
$colour-pizazz: #ff8a00;
$colour-chetwode-blue: #7780d9;
$colour-boston-blue: #428bca;
$colour-regal-blue: #204463;
$colour-wild-sand: #f5f5f5;
$colour-ronchi: #e8bb4d;
$colour-monza: #eb0000;
$colour-flush-orange: #ff7400;
$colour-chilean-fire: #f56f00;
$colour-koromiko: #ffb96a;
$colour-vermilion: #fc4702;
$colour-cornflower-blue: #5ea9eb;
$colour-malibu: #7a82ff;
$colour-stiletto: #8c2c31;
$colour-cerulean: #00a2e8;
$colour-lochmara: #0088cc;
$colour-dodger-blue: #369df7;
$colour-greyish-red: #f2dede;
$colour-pink: #FFC0CB;
$colour-congenica-red: #ff6187;
$colour-congenica-light-blue: #98e0ff;
$colour-congenica-dark-blue: #15234a;
$colour-congenica-yellow: #ffe8a3;

// Greys
$colour-white: #fff !default;
$colour-grey-100: #f8f9fa !default;
$colour-grey-200: #e9ecef !default;
$colour-grey-300: #dee2e6 !default;
$colour-grey-400: #ced4da !default;
$colour-grey-500: #adb5bd !default;
$colour-grey-600: #868e96 !default;
$colour-grey-700: #495057 !default;
$colour-grey-800: #343a40 !default;
$colour-grey-900: #212529 !default;
$colour-black: #000 !default;
$colour-dusty-gray: #999;
$colour-grey: #808080;
$colour-light-grey: #eee;

$colour-silver-chalice: #a7a7a7;
$colour-silver: #cccccc;
$colour-dove-grey: #666666;
$colour-mine-shaft: #333333;
$colour-loblolly: #bdc9ce;
$colour-selago: #f6f5fe;
$colour-gray19: #303030;
$colour-mercury: #e7e7e7;

$colour-transparent-black: transparentize($colour-black, $opacity-50);
$colour-transparent-mine-shaft: transparentize($colour-mine-shaft, $opacity-60);
