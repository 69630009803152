.transcript {
  &-row.location-row .column {
    border-bottom: solid black 1px;
  }

  &-allele {
    font-size: 14px;
    margin: 0;
  }

  &-chr {
    font-size: 18.2px;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
  }

  &-position {
    font-size: 10.5px;
    margin: 0;
    white-space: nowrap;
  }

  &-row {
    display: flex;
  }

  &-column {
    padding: 5px;
    border-bottom: solid black 1px;
    margin: 0;
  }
}
