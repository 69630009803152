@mixin pathogenicity-colour($colour) {
  background: $colour;
  border-color: $colour;
  color: $colour-white;
}

// Usage:
//    $type: This is an array of either key value pairs
//           E.G. ("color": red, "border-color": null). Null values will use the
//           pathogenicities colour
//           OR a list of string properties E.G. ("color", "background-color")
//    $pseudo-selectors: This is allows for customisation of additional pseudo
//           selectors as needed, generally this is for overwriting of bootstrap
//
// The $pseudo map approach here creates a set of duplication which sass will
// resolve for us, that said if this code can be rewritten to avoid this it
// would be ideal
@mixin generate-pathogenicity-styles(
  $type: "color",
  $pseudo-selectors: (
    "hover"
  )
) {
  @if is-map($type) {
    @each $property, $value in $type {
      @each $classname, $pathogenicity-value in $all-pathogenicities {
        @each $pseudo in $pseudo-selectors {
          &--#{$classname},
          &--#{$classname}:#{$pseudo} {
            @if $value != null {
              #{$property}: $value;
            } @else {
              #{$property}: $pathogenicity-value;
            }
          }
        }
      }
    }
  } @else {
    @each $classname, $value in $all-pathogenicities {
      @each $pseudo in $pseudo-selectors {
        &--#{$classname},
        &--#{$classname}:#{$pseudo} {
          #{$type}: $value;
        }
      }
    }
  }
}
