@import "../../../scss/_tools";

.pdf_viewer {
  display: flex;
  flex-direction: column;
  border: solid $base-border-colour $base-border-width;

  &__toolbar {
    display: flex;
    align-items: center;
    border-bottom: solid $base-border-colour $base-border-width;

    transition: opacity ease-in-out 0.2s;
    border-radius: 4px;

    &__left {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 10px;
    }

    &__center {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__right {
      span {
        font-size: $font-size-body-medium !important;
      }
    }

    &__label {
      font: inherit;
      font-size: 0.8em;
      padding: 0 0.5em;
    }

    &__divider {
      border-right: 1px solid #ced4da;
      height: 22px;
      margin: 0 3px;
    }

    &__button {
      width: 44px;
      height: 44px;
      border: 0;
      font: inherit;
      font-size: 0.8em;
      border-radius: 4px;

      &:enabled:hover {
        cursor: pointer;
      }

      &:enabled:hover,
      &:enabled:focus {
        background-color: $colour-grey-200;
      }
    }
  }

  &__container {
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;

    &__load {
      margin-top: 1em;
      color: white;
    }

    &__document {
      margin: 1em 0;

      [class~="react-pdf__Document"] {
        display: flex;
        flex-direction: column;
        align-items: center;

        &:hover .pdf_viewer__page-controls {
          opacity: 1;
        }
      }

      [class~="react-pdf__Page"] {
        max-width: calc(100% - 2em);
        box-shadow: 0 30px 40px 0 $colour-grey-500;
        margin: 1em;

        canvas {
          max-width: 100%;
          height: auto !important;
        }
      }

      [class~="react-pdf__message"] {
        padding: 20px;
        color: white;
      }
    }
  }
}
