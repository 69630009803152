.list {
  list-style-type:none;
  padding-left: 0;

  &-item {

    &-container {
      display: flex;
      justify-content: space-between;
      padding: $list-item-padding;
    }

    &-text {
      display: block;
    }

    &-text.highlighted {
      font-weight: bold;
    }

    &:hover {
      background-color: $colour-wild-sand;
    }

    &:nth-child(odd) {
      background: $list-item-odd-background-colour;
    }

    &.selected {
      background-color: $colour-boston-blue;
      color: $colour-white;
    }

    &.empty-list {
      color: $colour-dusty-gray;
      font-style: italic;
      cursor: default;
    }

    &-label {
      height: 100%;
      margin-top: auto;
      margin-bottom: auto;
    }
  }

}
