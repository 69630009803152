// Default variables for containers
// $draggable-checkbox-list-item-{subclass?}-{css property}

$draggable-checkbox-list-item-movable-cursor: grab;

$draggable-checkbox-list-item-input-margin-right: $spacing-small;
$draggable-checkbox-list-item-min-height: $spacing-medium;

$draggable-checkbox-list-item-arrow-top: $spacing-inline-small * 0.5;
$draggable-checkbox-list-item-arrow-padding-right: $spacing-inline-small;
$draggable-checkbox-list-item-arrow-font-size: $font-size-body-small;
$draggable-checkbox-list-item-arrow-color: $colour-grey-500;

$draggable-checkbox-list-is-dragging-opacity: 0.2;
