.project-irs-table {

  &-sub-component {
    &-header {
      margin: 5px 0 10px 0;
    }

    &-column {
      white-space: pre-line;
    }

    &-btn {
      &-reset {
        font-size: 2em;
      }
    }
  }
}
