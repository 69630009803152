.ui-overflow {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	display: none;
	background: $colour-white;
	z-index: $ui-overflow-z-index; // Should be above all other elements on the page

	&.center {
		justify-content: center;
		align-items: center;
	}

	&.visible {
		display: flex;
	}
}
