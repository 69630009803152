.gene {
  flex-basis: $gene-width;
  cursor: pointer;
  display: block;
  flex-grow: 0;
  flex-shrink: 0;
  padding: $base-inline-spacing;
  min-width: $gene-width;
  max-width: $gene-width;

  &-column {
    word-break: break-word;
  }

  &-table {
    display: flex;
    flex-direction: column;
    min-width: max-content;

    .no-variants-found {
      max-width: 100%;
      margin: 10px auto;

      .alert {
        flex-direction: row;
        flex-wrap: wrap;
        text-align: left;

        & > div:first-child {
          min-width: 100%;
          margin-bottom: -4px;
        }

        button {
          width: auto;
          margin: 0 0 0 15px;
          align-self: center;
        }
      }
    }
  }

  &-row {
    border-bottom: $spacing-border-width-xxl solid $colour-dusty-gray;
    display: flex;

    &:hover {
      .gene {
        background-color: $colour-selago;
      }
    }

    &--is-active {
      border-bottom-width: 0;

      > .gene {
        background: $gene-active-background-color;
        cursor: default;
      }
    }
  }

  &-description {
    font-style: italic;
  }

  &-name {
    display: inline-flex;
    align-items: center;
    padding: $base-inline-spacing $spacing-small $base-inline-spacing;
    font-weight: bold;
    line-height: $base-line-height;
    color: $colour-white;
    white-space: normal;
    border-radius: $base-border-radius-medium;
    border: $spacing-border-width-large solid $gene-button-background-color;
    background: $gene-button-background-color;
    font-size: $font-size-body-regular;
    margin: 0 0 $spacing-small;

    &:hover {
      background: $gene-button-hover-background-color;
    }

    &--no-pathogenicity,
    &:hover {
      background-color: white;
      color: $gene-button-background-color;
      cursor: pointer;
    }

    @include generate-pathogenicity-styles(
      (
        color: $colour-white,
        background-color: null,
        border-color: null,
      )
    );
  }

  &-header {
    display: flex;
    position: sticky;
    top: 0;
    z-index: $variants-table-header-z-index;
    flex-direction: column;

    h4 {
      background-color: $colour-white;
      margin: 0;
      padding: 0 0 20px;
    }

    &-row {
      display: flex;
      top: 0;
      background-color: $colour-loblolly;
      color: $colour-black;

      .variant-column {
        font-weight: bold;
        padding: $spacing-small;
        border-bottom: $spacing-border-width-medium * 2 solid #ddd;
        background-color: $colour-loblolly;
        align-items: flex-end;

        &.gene-header-cell {
          width: $gene-width;
          max-width: $gene-width;
          min-width: $gene-width;
          padding-left: $base-inline-spacing;
        }
      }
    }
  }

  &-panel-button {
    padding: 4px 7px 4px 7px !important;
  }
}
