.tutorial {
  &.tutorial-control {
    display: flex;
  }

  &-control {
    background: $help-background-colour;
    bottom: $help-tutorial-control-bottom;
    box-sizing: border-box;
    display: none;
    justify-content: space-between;
    left: 50%;
    margin-left: -$help-tutorial-control-width * 0.5;
    position: fixed;
    width: $help-tutorial-control-width;
    z-index: $help-z-index;

    &-progress {
      box-sizing: border-box;
      display: flex;
      justify-content: space-around;
      left: 0;
      list-style: none;
      position: absolute;
      top: $help-tutorial-control-progress-top;
      width: $help-tutorial-control-width;

      &-item {
        border: $base-border-width solid $base-border-colour;
        cursor: pointer;
        display: block;
        height: $help-tutorial-control-progress-bullet-diameter;
        width: $help-tutorial-control-progress-bullet-diameter;

        &.is-active {
          background-color: $help-tutorial-control-progress-bullet-background-colour;
        }
      }
    }
  }
}
