#variant-panel-container ul {
  list-style: none;
  margin: 10px 0 0 0;
  padding: 5px;
  background-color: #eeeeee;
}
#variant-panel-container li {
  display: block;
  margin: 0 0 3px 0;
  text-align: left;
}
#variant-panel-container li span.label {
  margin-left: 5px;
  float: right;
}

.variant-panel-button-container {
  margin-top: 10px;
}

.variant-panel-info {
  padding: 18px;
  margin-top: 6px;
}

.variant-panel-column {
  float: left;
  position: relative;
  width: 50%;
  padding-left: 15px;
  padding-right: 15px;
}
.variant-panel-column_has-decision-sibling, .variant-panel-column_decision {
  width: 33.33333333%;
}
@media (max-width: 1370px) {
  .variant-panel-column {
    width: 50%;
  }

  .variant-panel-column_decision {
    width: 100%;
  }
}
@media (max-width: 970px) {
  .variant-panel-column {
    width: 100%;
  }
}
