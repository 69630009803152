.timeline-comment {
  display: flex;
  margin-bottom: $spacing-x-small;

  &-container {
    border-left: $spacing-inline-small solid transparent;
    padding-left: $spacing-small;
    min-height: 56px;

    &:hover {
      border-left: $spacing-inline-small solid $colour-boston-blue;
    }
    &-body {
      font-size: calc(#{$font-size-body-regular} * 0.9);
      padding-bottom: $spacing-small;
    }

    &-title {
      color: $colour-dusty-gray;
      font-size: calc(#{$font-size-body-regular} * 0.9);
      font-weight: bold;

      &-username {
        &::after {
          content: "|";
          margin-left: $timeline-title-margin;
        }
      }

      &-day {
        margin-left: $timeline-title-margin;
      }
    }

    .label {
      margin: 0 $spacing-inline-small;

      &:first {
        margin-left: 0;
      }

      &:last {
        margin-right: 0;
      }
    }
  }

  &-date {
    min-width: $timeline-date-min-width;
  }
}
