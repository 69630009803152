.morbid-badge {
  font-size: $font-size-body-small;
  padding: $spacing-inline-small;
  border-radius: $spacing-border-radius-small;
  margin-left: $base-inline-spacing;
  // !important is used here to overwrite bootstrap
  background-color: $gene-morbid-background-color !important;
  color: $colour-white !important;
  font-weight: bold;
}
