.file-thumbnail {
  background-color: $colour-regal-blue;
  background-position: center;
  background-size: cover;
  cursor: pointer;
  height: $file-thumbnail-height;
  margin: 0 $spacing-small;
  position: relative;
  width: $file-thumbnail-width;

  &-labels {
    margin-bottom: $spacing-small;
  }

  &-filesize {
    color: $colour-grey-200;
    font-size: $font-size-body-small;
  }

  &:hover &-filename {
    text-decoration: underline;
  }

  &:hover .remove {
    display: block;
  }

  &:before {
    background-image: linear-gradient(
      $file-thumbnail-gradient-end 50%,
      $file-thumbnail-gradient-start 80%
    );
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  .remove {
    color: $colour-grey-400;
    display: none;
    position: absolute;
    right: $spacing-small;
    top: $spacing-small;

    &:hover {
      color: $colour-white;
    }
  }

  &-content {
    bottom: 0;
    color: $colour-white;
    left: 0;
    padding: $spacing-small;
    position: absolute;
    right: 0;
  }
}
