.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: 100%;
}

.react-datepicker {
  font-size: 1.3rem !important;
  &__close-icon {
    right: $spacing-inline-medium;
    bottom: $spacing-inline-large;
    position: absolute;
    &:after {
      bottom: inherit;
      color: grey;
      font-size: $font-size-body-medium;
      background-color: transparent;
    }
  }
}

.react-datepicker__current-month {
  font-size: 1.5rem;
}

.react-datepicker__header {
  padding-top: 6px;
}

.react-datepicker__navigation {
  top: 13px;
}

.react-datepicker__day-name, .react-datepicker__day {
  margin: 0.5rem;
}
