.article {
  align-items: stretch;
  display: flex;

  &-flag {
    color: $article-flag-color;
    cursor: pointer;
    transition: $article-transition;

    &:hover {
      text-shadow: 1px 1px 2px $article-flag-color;
    }

    &-bookmarked {
      color: $article-flag-bookmarked-color;

      &:hover {
        text-shadow: 1px 1px 2px $article-flag-bookmarked-color;
      }
    }
  }

  &-authors {
    font-style: italic;
  }

  &-source {
    font-weight: bold;
  }

  &-property {
    display: flex;

    &-journal {
      margin-right: $spacing-small * 11;
    }

    &-category {
      margin-left: $spacing-small;
    }

    &-label {
      font-weight: bold;
      margin-right: $article-property-label-margin-right;
    }

    &-value {
      word-break: normal;
      word-wrap: break-word;
    }
  }

  &-meta-info {
    display: flex;
    flex-direction: column;

    &-row {
      display: flex;
      margin: 0 $spacing-small $spacing-small;
    }
  }

  &-line {
    border: 0;
    min-width: $article-line-min-width;
  }

  &-content {
    display: flex;
    flex-direction: column;
  }

  &-normal {
    border: $base-border-width solid $base-border-colour;

    &-flag {
      font-size: $article-flag-font-size;
      width: $article-flag-width;
    }

    &-meta-info {
      display: flex;
      flex-direction: column;
      min-width: $article-normal-meta-info-min-width;

      &-row {
        &-fixed-height {
          min-height: $spacing-small * 10;
        }

        &-action {
          &-label {
            color: $article-normal-meta-info-row-action-label-color;
            font-weight: bold;
            margin: $article-normal-meta-info-row-action-label-margin;
            opacity: 0;
            text-decoration: underline;
            transition: $article-transition;

            &-bookmarked {
              color: $article-normal-meta-info-row-action-label-bookmarked-color;
              text-decoration: none;
            }
          }

          &:hover {
            .article-normal-meta-info-row-action-label {
              opacity: 1;
            }
          }
        }
      }
    }

    &-title {
      color: $article-title-color;
      cursor: pointer;
      font-weight: bold;
      margin: 0 0 ($spacing-small * 0.5);
      transition: $article-transition;

      &:hover {
        text-shadow: 1px 1px 2px $article-reference-meta-info-link-color;
      }
    }

    &-link {
      min-width: $article-normal-meta-info-min-width;
    }

    &-line {
      background-color: $article-normal-line-background-color;
      margin: $article-normal-line-margin;
      transition: $article-transition;
    }

    &-content {
      padding: $article-content-padding;
    }

    &-footer {
      margin: ($spacing-small) 0;
    }

    &:hover {
      .article-normal-line {
        background-color: $article-hover-background-color;
      }
    }
  }

  &-stripped-down {
    cursor: pointer;

    &-meta-info {
      display: flex;
      flex-direction: column;
      min-width: $article-reference-meta-info-min-width;
    }

    &-flag {
      margin-right: $article-stripped-down-meta-info-flag-margin-right;
    }

    &-title {
      transition: $article-transition;
    }

    &-line {
      background-color: $article-stripped-down-line-background-color;
      margin: $article-stripped-down-line-margin;
      opacity: 0;
      transition: $article-transition;
    }

    &-content {
      flex-direction: row;
      padding: $article-content-padding;
    }

    &:hover {
      .article {
        &-stripped-down {
          &-line {
            opacity: 1;
          }

          &-title {
            color: $article-hover-color;
          }

          &-flag {
            color: $article-hover-color;
          }
        }
      }
    }
  }

  &-reference {
    &-title {
      color: $article-title-color;
      cursor: pointer;
      font-weight: bold;
      margin: 0 0 ($spacing-small * 0.5);
      transition: $article-transition;

      &:hover {
        text-shadow: 1px 1px 2px $article-reference-meta-info-link-color;
      }
    }

    &-authors {
      margin: 0 0 ($spacing-small * 0.5);
    }

    &:hover {
      .article {
        &-reference {
          &-line {
            opacity: 1;
          }
        }
      }
    }

    &-meta-info {
      &-row {
        flex-direction: column;
        justify-content: center;
      }

      &-link {
        align-self: center;
        color: $article-reference-meta-info-link-color;
        cursor: pointer;
        margin: $article-reference-meta-info-link-margin;
        transition: $article-transition;

        &:hover {
          text-shadow: 1px 1px 2px $article-reference-meta-info-link-color;
        }
      }
    }

    &-line {
      background-color: $article-reference-line-background-color;
      opacity: 0;
      transition: $article-transition;
    }
  }
}
