.snv-presets {
  &-wrapper {
    flex-direction: column;
  }

  &-select {
    flex-grow: 1;
  }

  &-view {
    .glyphicon-list-alt {
      height: 100%;
      vertical-align: middle;
      padding-left: $spacing-border-width-medium;
      font-size: 24px;
      color: $colour-boston-blue;
    }
    margin-top: auto;
    margin-bottom: auto;
  }
}