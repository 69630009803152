.credits-controls {
  align-items: center;
  display: flex;
  padding: 12px 0;

  .title,
  .register-kit,
  .add-credits {
    margin-right: $spacing-medium;
  }
}
