.audit {
  &__info{
    &-section {
      display: flex;
    }
    &-left {
      width: 185px;
      font-weight: bold;
      text-align: right;
      margin-right: $spacing-small;
    }
    &-right {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
  }
}
