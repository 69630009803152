// Default variables for the group module
// $group-{subclass?}-{element?}-{css property}

$group-margin: $spacing-small;

$group-title-background-colour: $colour-white;
$group-title-font-size: $font-size-body-small;

$group-content-font-size: $font-size-body-large;

$group-min-width: 130px;

$group-round-border-margin: $spacing-small;
$group-round-border-color: $colour-grey-600;
$group-round-border-content-size: 120px;
$group-round-border-size: $group-round-border-content-size * 1.3;

$group-round-border-title-background-colour: $colour-white;
$group-round-border-title-font-size: calc(#{$font-size-body-medium} + 4px);
$group-round-border-title-line-height: 1.3;

$group-round-border-content-font-size: $font-size-body-large;
$group-round-border-content-background-color: $colour-grey-100;
