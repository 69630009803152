.info-field {
  align-items: center;
  display: flex;
  width: 100%;

  .name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &-left-icon {
      padding-left: $spacing-medium * 0.5;
    }

    &-right-icon {
      padding-right: $spacing-medium * 0.5;
    }
  }

  .icon {
    font-size: $font-size-body-medium;

    &-large {
      height: $font-size-body-medium * 2;
      width: $font-size-body-medium * 2;
      position: relative;
      & + .name-left-icon {
        padding-left: $spacing-medium;
      }
      &.ec-rep {
        width: 50px;
      }
    }
  }
}
