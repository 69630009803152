.slidedown {
  height: 0;
  transition-duration: $animation-duration-medium;
  transition-property: none;
  transition-timing-function: $animation-timing-function-ease;

  &.transitioning {
    overflow-y: hidden;
  }

  &.closed {
    display: none;
  }
}
