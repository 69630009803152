// Default variables for accordion
// $accordion-{subclass?}-{state?}-{css property}

$accordion-background-colour: #f7f7f7;
$accordion-border: 1px solid;
$accordion-border-colour: #d9d9d9;
$accordion-border-radius: $spacing-border-radius-small;
$accordion-section-header-padding-x: $spacing-medium;
$accordion-section-header-padding-y: $spacing-small;
$accordion-section-content-padding-x: $accordion-section-header-padding-x + $spacing-small;
$accordion-section-content-padding: 12px;
$accordion-section-background-color: #fff;
$accordion-header-line-height: 24px;
$accordion-header-font-size: $font-size-body-regular;
$accordion-header-margin-bottom: 4px;
$accordion-header-error-background-color: #ff9797;
$accordion-header-error-icon-color: #a94442;
