@import '../_tools/variables/general/spacing';
@import '../_tools/variables/general/fonts';
@import '../_tools/variables/general/colours';

.radiobuttons {
  &-label {
    display: flex;
    gap: $spacing-inline-medium;
    font-weight: normal;
    margin-left: $spacing-inline-medium;
    vertical-align: -1px;
    cursor: pointer;
    user-select: none;

    &-disabled {
      cursor: not-allowed;
      color: $colour-grey-500;
    }

    &-checked {
      cursor: default;
    }
  }

  &-cursor-pointer {
    cursor: pointer;
  }

  &-item {
    position: relative;
    display: block;
    margin-bottom: $spacing-inline-small;
  }

  &-item-inline {
    display: inline-block;
    margin-right: $spacing-small;
  }
}
