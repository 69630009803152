.component {
  h4 + [class="text-muted"] {
    margin-top: -7px;
    font-size: 85%;
  }
}

.section {
  max-width: 860px;

  &:last-child {
    // hack to cancel margin collapsing
    padding-bottom: 1px;
  }
}

.about {
  max-width: 780px;
  margin: 20px 0;
}

.aboutCaller {
  border: none;
  background: transparent;
}

.table {
  margin: 0;

  // Table component force add `.table-bordered` CSS class, which adds borders between cols, we don't need them here.
  // Such long selector is to overlap selectors specificity from `bootstrap.css`.
  & table > tbody > tr > td {
    border-width: 1px 0 0;
  }
}

.decProbTable {
  width: 250px;
  max-width: 100%;
}

.decProbRow_current {
  font-weight: bold;
}
