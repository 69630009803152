.settings-form {
  display: flex;
  flex-direction: column;

  .title {
    font-size: $font-size-body-medium;
  }

  .row {
    align-items: center;
  }

  .control {
    display: flex;
    justify-content: center;
    padding: $base-spacing;

    .button {
      margin: 0 $spacing-small;
    }
  }
}
