.icon {
  @include transition(color);
  display: inline-block;
  font-size: $icon-regular-font-size;
  text-align: center;

  &-container {
    &--center {
      text-align: center;
    }
  }

  &--with-caption {
    padding: $base-spacing;
  }

  &--grey {
    color: $icon-colour;
  }

  &--giant {
    font-size: $icon-giant-font-size;
  }

  &--large {
    font-size: $icon-large-font-size;
    width: $icon-large-font-size;
  }

  &-caption {
    color: $icon-caption-colour;
    font-size: $base-font-size;
    margin-bottom: 0;
    margin-top: $base-inline-spacing;
  }

  &--success {
    color: $base-success-colour;
  }

  &--fail {
    color: $base-fail-colour;
  }

  &--disc {
    background-color: transparent;
    border-radius: 50%;
    color: $icon--disc-colour;

    &.icon--success {
      background-color: $base-success-colour;
    }

    &.icon--fail {
      background-color: $base-fail-colour;
    }
  }

  &-custom {
    height: $font-size-body-medium;
    width: $font-size-body-medium;
    &:before {
      content: '';
      display: block;
      height: 100%;
      background-size: 100%;
      background-repeat: no-repeat;
    }
    &.ce-mark {
      &:before {
        background-image: url(/images/ce-mark.svg);
      }
    }
    &.instructions {
      &:before {
        background-image: url(/images/instructions.svg);
      }
    }
    &.ivd {
      &:before {
        background-image: url(/images/ivd.svg);
        margin-top: $spacing-x-small;
      }
    }
    &.warning {
      &:before {
        background-image: url(/images/warning.svg);
      }
    }
    &.caution {
      &:before {
        background-image: url(/images/caution.png);
      }
    }
    &.manufacturer {
      &:before {
        background-image: url(/images/manufacturer.svg);
      }
    }
    &.ec-rep {
      &:before {
        background-image: url(/images/ec-rep.svg);
        margin-top: 6px;
      }
    }

    &.uk-flag {
      &:before {
        background-image: url(/images/uk-flag.svg);
        margin-top: 3px;
      }
    }
    &.usa-flag {
      &:before {
        background-image: url(/images/usa-flag.svg);
        margin-top: 3px;
      }
    }
    &.eu-flag {
      &:before {
        background-image: url(/images/eu-flag.svg);
      }
    }
  }
}
