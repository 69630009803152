.pane {
  display: block;
  min-width: $pane-min-width;
  width: $pane-width;

  &-border {
    border: $base-border-width $base-border-style $colour-grey-400;
    border-top: none;
  }
  
  &-row {
    display:flex;
    width:100%;
    flex-direction: row;

    &:nth-child(odd) {
      background-color: $pane-row-odd-background-colour;
    }

    &-item {
      border-top: $pane-row-item-border-width solid
        $pane-row-item-border-top-colour;
      display: block;
      flex-grow: 1;
      flex-basis: 50%;
      vertical-align: middle;
      padding: $pane-row-item-padding;
    }

    &-key {
      font-weight: $pane-row-key-font-weight;
    }

    &-value {
      font-weight: $pane-row-value-font-weight;
      padding-right: $pane-row-value-padding-right;

      &-clickable {
        color: $colour-boston-blue;
        cursor: pointer;
      }

      .pane-row-key {
        margin-bottom: $spacing-x-small;
      }
    }

    &-actions {
      display: flex;
      justify-content: flex-end;
      padding-bottom: $spacing-small;
      padding-top: $spacing-small;

      &-sticky {
        bottom: 0;
        position: sticky;
      }

      &-btn {
        margin-left: $spacing-small;
      }
    }
  }
}
