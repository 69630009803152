@import "src/scss/_tools/variables/bootstrap-media-queries-vars";

.actions {
  display: flex;
  justify-content: flex-end;

  &--start{
    justify-content: flex-start;
  }

  &--end{
    justify-content: flex-end;

    @media (max-width: $screen-xs-max) {
      .dropdown-menu {
        left: auto;
        right: 0;
      }
    }
  }

  &--space-around {
    justify-content: space-around;
  }

  &--sticky {
    position: sticky;
    bottom: 0;
    z-index: 3; //to override bootstrap input-group
  }

  &-btn {
    margin: 0 $spacing-inline-medium;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    .icon + .actions-label {
      margin-left: $spacing-inline-medium;
    }

    &.btn--small {
      margin: 0 $spacing-inline-small;

      .icon + .actions-label {
        margin-left: $spacing-inline-small;
      }
    }

    .glyphicon {
      top: 2px;
    }
  }
}
