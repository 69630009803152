@use "sass:math";

$pane-min-width: $spacing-small-width * 0.45;
$pane-width: 100%;
$pane-row-odd-background-colour: $colour-grey-100;
$pane-row-item-border-top-colour: $colour-grey-300;
$pane-row-item-border-width: $spacing-border-width-medium;
$pane-row-item-padding: $spacing-small $spacing-medium $spacing-small
  $spacing-small;
$pane-row-key-font-weight: 500;
$pane-row-value-padding-right: math.div($spacing-medium, 1.2);
$pane-row-value-font-weight: bold;
