.progress {
  position: relative;

  &-bar {
    position: absolute;

    &-children {
      align-items: center;
      display: flex;
      font-size: $font-size-body-regular;
      height: 100%;
      justify-content: center;
      padding: $spacing-inline-small * 2;
      position: absolute;
      width: 100%;
    }

    &--left-justified {
      justify-content: left;
    }

    &--compact {
      position: relative;
      width: initial;
    }
  }
}
