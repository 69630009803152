@import "src/scss/_tools/variables/general/spacing";
@import "src/scss/_tools/variables/general/colours";
@import "src/scss/_tools/variables/general/fonts";

.fileUploader {
  &_file {
    display: flex;
    align-items: center;
    margin: $spacing-border-width-large;

    &_icon{
      min-width: $spacing-medium;
    }

    &_name {
      margin-left: $spacing-small;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &_progress {
    display: flex;
    align-items: center;
    &_component {
      flex: 1;
      margin-left: $spacing-small;
    }
  }
}
