.promo {
  font-size: $promo-font-size;
  padding: $base-inline-spacing;

  &-label {
    margin: 0;
    padding-bottom: (
        ($spacing-medium + 2) - ($promo-font-size * $base-line-height)
      ) * 0.5;
    padding-top: (
        ($spacing-medium + 2) - ($promo-font-size * $base-line-height)
      ) * 0.5;
    white-space: nowrap;

    &-container {
      padding-left: $base-inline-spacing;
    }
  }

  &-group {
    max-width: $promo-max-width;
  }

  &-icon-container {
    display: flex;
    justify-content: center;
  }
}
