.btn {
  &-outline {
    background-color: transparent;
    color: #666;

    &:hover {
      background-color: #ccc;
    }
  }
  &-icon-text {
    .icon {
      margin-right: $spacing-x-small;
    }
  }

  @include generate-pathogenicity-styles(
    (
      "background-color": null,
      "border-color": null,
      "color": white
    ),
    ("hover", "active", "focus", "hover:active")
  );
}
