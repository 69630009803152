@import "../_tools/variables/general/spacing";
@import "../_tools/variables/general/colours";

.projects-container {
  .sub-folder {
    display: flex;

    &-rare-disease {
      .glyphicon {
        color: $colour-congenica-light-blue;
      }
    }
    &-oncology {
      .glyphicon {
        color: $colour-congenica-red;
      }
    }

    .sub-folder-space-indent {
      &:before {
        content: "";
        margin-left: $spacing-medium;
      }
    }

    .sub-folder-name {
      margin-left: $spacing-x-small;
    }

    &.active {
      .glyphicon {
        color: $colour-white;
        &before {
          content: "\e118" !important;
        }
      }
    }
  }

  // TODO: Fix structure of DOM so the active state is appended elsewhere
  .active {
    .sub-folder {
      &-rare-disease {
        .glyphicon {
          color: $colour-white;
        }
      }
      &-oncology {
        .glyphicon {
          color: $colour-pink;
        }
      }
    }
  }

  .projects-table {
    position: initial;

    tbody tr {
      cursor: pointer;
    }
  }
}
