.form-group {
  &.table-cell-checkbox {
    // bootstrap overrides for table
    margin-right:0;
    margin-left: 0;
  }

  &.pre-line {
    white-space: pre-line;
  }
}
