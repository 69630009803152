@import "src/scss/_tools/variables/bootstrap-media-queries-vars";

.component {
  &Buttons {
    @media (max-width: $screen-xs-max) {
      margin: 10px 0 20px;

      button {
        float: none !important;
        margin: 0 10px 0 0 !important;

        &:last-child {
          margin-right: 0 !important;
        }
      }
    }
  }
}
