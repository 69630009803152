.sequence-runs {
  &-header {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
}

.sample-file {
  align-items: center;
  display: flex;
  width: 100%;

  .name {
    overflow: hidden;
    padding-right: $spacing-medium;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .icon {
    font-size: $font-size-body-medium;
    margin-left: calc(-#{$spacing-medium} + 1px);
  }
}

.sample-detail-modal {
  .group-content {
    font-size: calc(#{$font-size-body-medium} * 2);

    .icon {
      margin-right: calc(#{$spacing-medium} / 2);
    }
  }
}

// Variables specific to samples panel group
$status-min-width: 160px;
$status-margin: 7px 9px;
$status-group-content-margin: 23px $spacing-medium;

.samples-status-panel {
  display: flex;

  .status {
    margin: $status-margin;
    max-width: $status-min-width;

    .icon {
      margin-right: $spacing-small;
    }

    .group-content {
      margin: $status-group-content-margin;
    }

    .text {
      font-size: $font-size-body-regular;
    }
  }
}

.samples-upload-container {
  .buttons-container {
    margin-bottom: $spacing-medium;
  }

  .progress-bar {
    margin: 0;
  }

  .btn {
    padding-bottom: $spacing-medium;
    padding-top: $spacing-medium;
  }

  .upload-button {
    margin-right: $spacing-medium;

    .icon {
      font-size: $font-size-body-medium;
      padding-right: $spacing-small;
    }
  }
}
