.criteria-cell {
  text-align: center;

  .label {
    cursor: pointer;
    margin: 0 5px;
    user-select: none;
  }
}

#acmg-pathogenicity {
  // Set a new stacking context at the component level
  position: relative;
  z-index: 50;
}

.acmg-table {
  thead th {
    text-align: center;
  }

  thead > tr:first-child > th:first-child,
  tbody th {
    position: sticky;
    left: -1px;
    text-align: left;
  }
}

.locked {
  .label {
    cursor: not-allowed;
  }
}

.locked-cell {
  cursor: not-allowed !important;
}

.acmg-classification {
  &-container {
    // Overwrite bootstrap's height on form control elements
    display: flex !important;
    justify-content: space-between;
    padding: 5px;
    margin: 0 0 10px;
    border: solid $acmg-classification-border-colour 1px;
    // Overwrite bootstrap's height on form control elements
    height: auto !important;
    cursor: pointer !important;

    .criteria {
      margin: 1px 1px;
    }
  }
}

.criteria-list {
  max-width: 60%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: flex-end;
  text-align: right;
}

.overlay {
  display: none;

  &.active {
    position: absolute;
    top: 100%;
    z-index: 100;
    background-color: white;
    display: block;
    right: 0;
    border: solid $acmg-overlay-border-colour 1px;

    table {
      margin-bottom: 0 !important;
    }
  }

  &-hidden {
    display: none;
    &.active {
      display: block;
      position: fixed; /* Sit on top of the page content */
      width: 100%; /* Full width (cover the whole page) */
      height: 100%; /* Full height (cover the whole page) */
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0); /* Black background with opacity */
      z-index: 98;
    }
  }

  &-container {
    position: relative;
  }
}

.btn-group {
  &.active {
    // Promote lock/unlock button group above active overlay
    z-index: 99;
  }
}

.btn-locked .glyphicon-lock {
  color: $acmg-icon-locked-colour !important;
}

.glyphicon-lock {
  color: $acmg-icon-colour !important;
}

.acmg-criteria-list {
  height: 450px;
  overflow-y: scroll;
}

.acmg-suggestion-panel {
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  background-color: #f7f7f7;
  display: flex;
  flex-wrap: nowrap;
  font-size: 14px;
  font-weight: 500;
  height: 100%;
  hyphens: auto;
  line-height: 24px;
  margin-bottom: 4px;
  padding: 9px;
  word-break: break-word;

  input[type="checkbox"] {
    display: none;
  }

  input[type="checkbox"] + label {
    opacity: 0.4;
    transition: opacity 300ms;
  }

  input[type="checkbox"]:checked + label {
    opacity: 1.0;
    transition: opacity 300ms;
  }

  .checkbox-label {
    .label {
      cursor: pointer;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
  }

  .hint {
    color: #777;
    font-size: 11px;
    line-height: 12px;
  }

  .alert-warning {
    font-weight: normal;
  }
}

.acmg-suggestion-icon {
  color: $colour-boston-blue;
  font-size: 1.5em !important;
  cursor: pointer;
  &:focus {
    outline: none;
  }
}
