.tab-content {
  .modal-body {
    & > .row {
      margin: 0;
    }

    .tabs {
      margin: 0;
    }
  }
}

.nav-tabs {
  margin-bottom: $tab-padding-medium;

  li > a {
    color: $colour-mine-shaft;
    border-radius: $spacing-border-radius-medium $spacing-border-radius-medium 0
      0;
    border: 1px solid $colour-mercury;
    margin-top: $spacing-x-small;
    margin-right: $spacing-inline-small;
    transition: all 0.2s ease-in-out 0s;
  }
}

.tabs {
  $error-light-red: #f2dede;
  $error-dark-red: #a94442;

  .nav {
    li {
      a {
        display: flex;
        cursor: pointer;
        gap: $spacing-x-small;
        align-items: center;
        img,
        .icon {
          height: $font-size-body-regular * 1.4;
          width: $font-size-body-regular * 2;
          margin-right: $spacing-x-small;
        }
      }
    }

    &.nav-stacked {
      li.error {
        a {
          color: $error-dark-red;
          background-color: $error-light-red;
          transition: background-color 0.3s;
          display: block;

          &::after {
            font-family: "FontAwesome";
            font-weight: 900;
            font-size: 15px;
            content: "\f06a";
            color: $error-dark-red;
            transition: color 0.3s;
            float: right;
            margin-left: 10px;
          }
        }

        &.active {
          a {
            color: $error-light-red !important;
            background-color: $error-dark-red !important;
            transition: background-color 0.3s;

            &::after {
              color: $error-light-red;
            }
          }
        }
      }
    }
  }

  .nav-tabs {
    li.error {
      a,
      a.active {
        color: $error-dark-red;
        background-color: $error-light-red;
        transition: background-color 0.3s;
        display: block;

        &::after {
          font-family: "FontAwesome";
          font-weight: 900;
          font-size: 15px;
          content: "\f06a";
          color: $error-dark-red;
          transition: color 0.3s;
          float: right;
          margin-left: 10px;
        }

        &.active {
          color: $error-light-red !important;
          background-color: $error-dark-red !important;
          border-bottom: 1px solid $error-dark-red !important;
          border-left: 1px solid $error-dark-red !important;

          &::after {
            color: $error-light-red;
          }
        }
      }
    }
  }
}
