.tier {
  &-complete {
    &,
    &:hover {
      background-color: $colour-cerulean;
      color: $colour-white;
      border-color: $colour-boston-blue;
    }
  }

  &-incomplete {
    &,
    &:hover {
      background-color: $colour-white;
      color: $colour-cerulean;
      border-color: $colour-cerulean;
    }
  }

  &-container {
    display: inline-flex !important;
    margin: $spacing-inline-small 0;
  }
}
