@import "../_tools/variables/general/spacing";

.ref-no-call {
  padding-right: $spacing-small;

  &__filter-label {
    margin-right: $spacing-inline-large;
  }

  &__filter-items {
    padding-top: $spacing-small;
  }

  &__filter-text {
    display: flex;
    flex-direction: row-reverse;
  }

  &__footer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;

    &-left {
      padding-top: 27px;
    }

    &-center {
      flex: 1;
      text-align: center;
    }

    &-right {
      padding-top: 19px;
      width: 95px;
      white-space: nowrap;
    }

    &-perpage-label {
      opacity: 0.5;
    }
  }
  &__header-block {
    background: #e4ecf2;
    padding: 10px 16px;
    margin: 16px 0;
    display: flex;
  }
  &__header-block-right {
    margin-left: 100px;
  }
  &__header-block-spacer {
    flex: 1;
  }
}
