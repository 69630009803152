.sequence-run {
  &-header {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .status {
      &--icon {
        margin-right: 6px;
      }

      &--link {
        align-items: center;
        display: flex;
        text-decoration: none;
      }
    }
  }

  &-footer {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-top: 9px;
    width: 100%;
  }
}
