.message {
  &-container {
    position: fixed;
    bottom: 0;
    left: 30px;
    min-width: 400px;
    max-width: 400px;
    transition: 0.5s all linear;
    overflow: hidden;
    margin-bottom: 0;
    z-index: 1051;

    .glyphicon {
      transition: 0.5s linear transform;
      transform: translateY(-50%);
      position: absolute;
      right: 5px;
      top: 50%;
    }

    .alert {
      width: 360px;
    }

    .panel-heading {
      padding-right: 26px;
      position: relative;
    }

    .panel-body {
      overflow-y: auto;
      overflow-x: hidden;
      max-height: 150px;
      overflow-wrap: break-word;
    }

    &--is-collapsed {
      min-width: 65px;
      max-width: 65px;

      .glyphicon {
        transform: rotate(180deg) translateY(50%);
        top: 50%;
      }
    }

    .panel:last-child {
      margin-bottom: 0;
    }

    .alert {
      margin-bottom: 5px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &-enter-active {
    animation: enterMessage 0.4s;
    animation-fill-mode: forwards;
    position: relative;
  }

  &-exit-active {
    animation: dismissMessage 0.75s;
    animation-fill-mode: forwards;
    position: relative;
  }
}

@keyframes enterMessage {
  0% {
    max-height: 0;
    border: 0;
    padding: 0;
    margin: 0;
    overflow: hidden;
    display: none;
  }

  5% {
    max-height: 0;
    padding-top: 0;
    border-top: 0;
    padding-bottom: 0;
    border-bottom: 0;
    margin-bottom: 0;
    overflow: hidden;
  }

  50% {
    left: -200%;
  }

  100% {
    left: 0;
  }
}

@keyframes dismissMessage {
  0% {
    left: 0;
  }

  50% {
    left: 200%;
  }

  99% {
    max-height: 0;
    padding-top: 0;
    border-top: 0;
    padding-bottom: 0;
    border-bottom: 0;
    margin-bottom: 0;
    overflow: hidden;
  }

  100% {
    max-height: 0;
    border: 0;
    padding: 0;
    margin: 0;
    overflow: hidden;
    display: none;
  }
}
