.patient_metadata {

  &-form {
    &-body {
      padding-bottom: 0;
    }

    &-footer {
      margin: 0 -15px;
    }

    &-editor {

      &-toggle {
        .checkbox label {
          padding-left: 0 !important;
        }
      }
    }
  }
}
