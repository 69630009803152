// Spacing
// $spacing-{css-property}-{size}
// Size being small, medium and large

// General spacing, for padding/margin typically
@use "sass:math";

$spacing-medium: 18px;
$spacing-large: $spacing-medium * 1.5;
$spacing-small: $spacing-medium * 0.5;
$spacing-x-small: math.div($spacing-medium, 3);
$tab-padding-medium: math.div($spacing-medium, 6) * 5;

$spacing-container: 0 $spacing-medium * 4;

// Inline spacing, for use around inline elements
$spacing-inline-small: 2px;
$spacing-inline-medium: math.div($spacing-medium, 3);
$spacing-inline-large: $spacing-inline-medium * 2;

$spacing-line-height-medium: 1.15;
$spacing-line-height-large: 1.5;

// Border spacing
$spacing-border-width-medium: 1px;
$spacing-border-width-large: 3px;
$spacing-border-width-xxl: 5px;

$spacing-border-radius-small: 2px;
$spacing-border-radius-medium: 5px;
$spacing-border-radius-large: $spacing-border-radius-medium * 2;

// Widths
$spacing-small-width: 920px;

// Heights
$container-height: 1000px;
