.str-panel {
  display: flex;
  justify-content: flex-start;

  div.col-md-4:first-child {
    padding-left: 0 !important;
  }

  div.variant-column.tier {
    padding-left: 0;
  }

}

.str-record {
  margin-top: 36px;
}

.str-table {
  .table-striped > tbody > tr > td {
    cursor: pointer;
  }
}

div.str-gene-panels > *{
  cursor: default;
}

.single-str-table {
  cursor: default;
  .table-striped > tbody > tr > td{
    background-color: #eeecfd;
  }
}

div.image-icon {
  text-align: center;
  cursor: pointer;
}

div.str-image-modal > div.modal-dialog {
  width: 95%;

  img.str-image {
    width: 100%
  }
}

div.str-details button.expand-table-button {
  border: 0;
  padding: 0 12px 0 12px;
}
