@import "src/scss/_tools/variables/bootstrap-media-queries-vars";

.component {
  float: right;

  @media (max-width: $screen-xs-max) {
    float: none;
    margin-top: 20px;
  }
}
