html {
  min-height: 100%;
  position: relative;
}

.container {
  max-width: none !important;
  padding: 0px;
}

.container.full-width {
  width: 99%;
}

body.index .container {
  max-width: 1170px !important;
  margin-bottom: 100px;
}

body.index .row {
  margin-left: 0;
  margin-right: 0;
}

.label {
  padding: 0.3em 0.6em 0.3em;
}

.form-control {
  margin-top: 0;
}
.form-group button {
  margin-right: 15px;
}

.m {
  @for $i from 0 through 10 {
    &x {
      &-#{$i} {
        margin: 0 $spacing-small * $i;
      }
    }
    &y {
      &-#{$i} {
        margin: $spacing-small * $i 0;
      }
    }

    &t {
      &-#{$i} {
        margin-top: $spacing-small * $i;
      }
    }

    &b {
      &-#{$i} {
        margin-bottom: $spacing-small * $i;
      }
    }

    &l {
      &-#{$i} {
        margin-left: $spacing-small * $i;
      }
    }

    &r {
      &-#{$i} {
        margin-right: $spacing-small * $i;
      }
    }

    &-#{$i} {
      margin: $spacing-small * $i;
    }
  }
}

.flex {
  display: flex;

  &-grow {
    flex-grow: 1;
  }
}

.p {
  @for $i from 0 through 10 {
    &x {
      &-#{$i} {
        padding: 0 $spacing-small * $i;
      }
    }
    &y {
      &-#{$i} {
        padding: $spacing-small * $i 0;
      }
    }

    &t {
      &-#{$i} {
        padding-top: $spacing-small * $i;
      }
    }

    &b {
      &-#{$i} {
        padding-bottom: $spacing-small * $i;
      }
    }

    &l {
      &-#{$i} {
        padding-left: $spacing-small * $i;
      }
    }

    &r {
      &-#{$i} {
        padding-right: $spacing-small * $i;
      }
    }

    &-#{$i} {
      padding: $spacing-small * $i;
    }
  }
}

.display {
  &-block {
    display: block;
  }
}

.sv-columns-menu-container {
  width: 250px;
}

.react {
  .text-muted {
    color: $colour-dusty-gray;
  }

  .label-default {
    background-color: $colour-dusty-gray;
  }

  a {
    cursor: pointer;
  }

  table td[class*="col-"],
  table th[class*="col-"] {
    position: relative;
  }

  .primary-modal-header {
    background-color: #0088cc;
    color: #ffffff;
    min-height: 16.42857143px;
    padding: 15px;
    border-bottom: 1px solid #e5e5e5;
    display: flex;
    justify-content: space-between;
  }

  .caret {
    margin-left: 2px;
  }

  .form_processing {
    display: block;
    padding: $spacing-medium 0;
    margin: $spacing-medium;
    text-align: center;
    font-size: $font-size-body-standard * 2;
    color: $colour-dusty-gray;
  }

  .banner-image {
    margin: 0 auto 20px;
  }

  .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }

  h4.header-container {
    margin: 17px 15px 0;
    float: right;

    @media (max-width: $screen-xs-max) {
      float: none;
      flex-direction: column;
      align-items: flex-start;
      margin: 17px 0 0;

      .header-description {
        margin-left: 0;
      }
    }
  }

  .icon {
    font-size: 1em;
  }

  .icon-success {
    color: $colour-fern;
  }

  .icon-error {
    color: $colour-roman;
  }

  .btn-group {
    .actions-btn {
      margin: 0;

      & + .actions-btn {
        margin-left: -1px;
      }
    }
  }

  .alert.alert-adding-gene-panel {
    white-space: initial;
  }

  .alert.alert-adding-gene-panel form {
    margin-top: 5px;
  }

  .navbar-nav .btn-link {
    padding: 9px 14px;
  }

  h1 small,
  h2 small,
  h3 small,
  h4 small,
  h5 small,
  h6 small,
  .h1 small,
  .h2 small,
  .h3 small,
  .h4 small,
  .h5 small,
  .h6 small,
  h1 .small,
  h2 .small,
  h3 .small,
  h4 .small,
  h5 .small,
  h6 .small,
  .h1 .small,
  .h2 .small,
  .h3 .small,
  .h4 .small,
  .h5 .small,
  .h6 .small {
    font-weight: normal;
    line-height: 1;
    color: $colour-dusty-gray;
  }

  .nav-tabs {
    clear: both;

    .label {
      margin-left: $spacing-x-small;
    }
  }

  .alternative-tabs-style {
    & > div {
      & > .tabs-contents {
        margin-top: 1px;
        padding: 0;
      }

      & > ul {
        background-color: $colour-lochmara;
        border: 1px solid $colour-lochmara;
      }

      & > .nav-tabs {
        margin-top: -20px;
        margin-bottom: 15px;

        & > li {
          & > a {
            margin: 3px 5px 0px 3px;
          }

          & > a,
          & > a:focus,
          & > a:active,
          & > a:hover {
            border: 0px;
            border-radius: 4px;
            color: #fff;
            margin: 4px 2px;
            padding: 8px 18px;
          }

          & > a.active,
          & > a:hover {
            background-color: $colour-dodger-blue !important;
          }
        }
      }
    }
  }

  .tabs-contents {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .navbar-brand-label {
    font-size: 13.5px;
    margin-left: 25px;
  }
}

.nav-tabs {
  margin-bottom: 15px;
}
.nav-tabs > li > a {
  margin-top: 6px;
  transition: all 0.2s ease-in-out 0s;
  line-height: 1.42857;
}
.nav-tabs a.active,
.nav-tabs a.active:hover,
.nav-tabs a.active:focus {
  cursor: default;
}

.tab-content {
  padding: 5px 0 30px 0;
}

table.data-table {
  clear: both;
  margin-bottom: 6px !important;
  max-width: none !important;
}
table.data-table tr[data-href],
table.data-table tr[data-target] {
  cursor: pointer;
}
div.dataTables_info h4 {
  line-height: 1.33;
  padding-right: 24px;

  @media (max-width: $screen-xs-max) {
    margin: 0;
    padding: 0;
  }
}
div.dataTables_filter {
  float: right;

  @media (max-width: $screen-xs-max) {
    float: none;
    max-width: auto;
    margin-right: 0;
  }
}
div.dataTables_length select {
  width: 100px;
}
div.dataTables_paginate {
  margin: 0;
}
div.dataTables_info h4 {
  display: inline-block;
}
div.dataTables_scrollHead table {
  margin: 0 !important;
}
div.dataTables_scrollBody table {
  margin-bottom: 0 !important;
}
div.table-buttons button {
  width: 100%;
}
div.table-buttons.inline-buttons button {
  width: auto;
  margin-left: 10px;
  float: right;
}

table.data-table thead .sorting,
table.data-table thead .sorting_asc,
table.data-table thead .sorting_desc,
table.data-table thead .sorting_asc_disabled,
table.data-table thead .sorting_desc_disabled,
table.data-table-ajax thead .sorting,
table.data-table-ajax thead .sorting_asc,
table.data-table-ajax thead .sorting_desc,
table.data-table-ajax thead .sorting_asc_disabled,
table.data-table-ajax thead .sorting_desc_disabled {
  cursor: pointer;
  *cursor: hand;
}

.dataTables_info h4,
.dataTables_filter {
  padding-top: 10px;
  padding-bottom: 5px;
}

.top-buffer {
  margin-top: 20px;
}

.toggleable {
  display: none;
}

#messages {
  bottom: 0;
  left: 50%;
  margin-left: -25%;
  position: fixed;
  width: 50%;
  z-index: 1000000000;
  max-height: 50%;
  overflow-y: auto;
}

#page-message {
  height: 22px;
}
#page-message .close {
  display: none;
}

.tab-pane > .nav-tabs {
  margin-top: -20px;
  padding-left: 0px;
}
.tab-pane > .nav-tabs > li > a,
.tab-pane > .nav-tabs > li > a:focus,
.tab-pane > .nav-tabs > li > a:active,
.tab-pane > .nav-tabs > li > a:hover {
  margin: 3px 5px 0px 3px;
}

.alert {
  white-space: pre-wrap;
  margin: 0;
}
.loading-msg {
  padding: 30px;
}

.modal.info {
  width: 100%;
}
.modal.info .modal-content .modal-body {
  overflow-y: auto;
  max-height: 450px;
}
.modal.info .modal-dialog {
  width: 1192px;
}

.bootstrap-datetimepicker-widget .datepicker {
  z-index: 2000;
}
.bootstrap-datetimepicker-widget .datepicker th.prev,
.bootstrap-datetimepicker-widget .datepicker th.next {
  width: 35px;
}

/* Fix tooltip left positioning and stop Genoverse tooltips overlapping */
.tooltip-inner {
  padding: 4px 8px;
}
.tooltip.left {
  margin-left: -10px;
}

.snv-gene h2 {
  margin: 5px 0 20px;
}
.snv-gene h3 {
  margin: 5px 0;
}
.snv-gene ul li {
  margin-bottom: 10px;
}

/* HPO -> phenotype table drag and drop */
a.hpo-tree.ui-draggable-dragging {
  padding: 4px 6px;
}
a.hpo-tree.ui-draggable-dragging i {
  display: none;
}

#hpo-tree ul.tree {
  padding-left: 20px;
}
#hpo-tree > ul.tree {
  margin-left: 0;
}
#hpo-tree ul.tree li {
  clear: left;
}
#hpo-tree ul.tree li a {
  word-wrap: break-word;
}
#hpo-tree ul.tree li a,
#hpo-tree ul.tree li .add {
  float: left;
}
#hpo-tree ul.tree li .add {
  display: none;
  height: 15px;
  margin: 3px 0 0 2px;
}
#hpo-tree ul.tree li .add form {
  display: inline-block;
  height: 100%;
}
#hpo-tree ul.tree li .add button {
  margin: 0 2px;
  height: 100%;
  width: 15px;
}
#hpo-tree ul.tree li > div {
  height: 20px;
}
#hpo-tree ul.tree li > div:hover > .add {
  display: inline;
}
#hpo-tree ul.tree li.open > div i.closed,
#hpo-tree ul.tree li.closed > div i.open,
#hpo-tree ul.tree li.closed ul {
  display: none;
}
#hpo-tree .glyphicon {
  padding-bottom: 5px;
  margin-right: 2px;
}

#hpo-search .query {
  padding-right: 23px;
}
#hpo-search .reset {
  position: absolute;
  margin: 10px 0 0 -20px;
  cursor: pointer;
  z-index: 3;
}

#phenotype-tables.fixed {
  position: fixed;
  top: 0;
}
#phenotype-tables.absolute {
  position: absolute;
}

.genoverse {
  margin-bottom: 20px;
  position: relative;
}

.karyotype .chr {
  margin-bottom: 25px;
}
.karyotype .chr .gv_wrapper {
  overflow: visible;
  display: inline-block;
  margin-left: -5px;
}
.karyotype .chr .label_container {
  width: 45px;
  float: none;
  display: inline-block;
}
.karyotype.syndrome .chr .label_container {
  width: 76px;
}
.karyotype .chr .label_container li {
  line-height: 10px;
}
.karyotype .chr .label_container li .handle {
  display: none;
}
.karyotype .chr .label_container li .name {
  top: 50%;
  left: 0 !important;
  margin: -5px 0 0;
}

#search-results .browser .canvas_container .label_container {
  padding-top: 50px;
}
#search-results .browser .canvas_container .label_container {
  margin-right: 10px;
}
#search-results .browser .canvas_container .label_container li .handle {
  display: none;
}
#search-results .browser .canvas_container .label_container li .name {
  width: 100%;
  left: 0 !important;
}
#search-results .browser .canvas_container .gv_karyotype_viewpoint {
  cursor: pointer;
}

.tooltip-inner {
  padding: 4px 8px;
}
.tooltip.left {
  margin-left: -10px;
}

.table-filter > div {
  display: inline-block;
  float: none;
}
.table-filter .dataTables_filter {
  margin-left: 10px;
}

#bulk-upload-preview .errors {
  display: inline-block;
  padding: 10px 10px 0;
  margin-bottom: 10px;
}

.photo-holder {
  margin: 10px;
  position: relative;
  display: inline-block;
}
.photo-holder img {
  vertical-align: top;
}
.photo-holder .btn {
  padding: 2px 4px 0;
  position: absolute;
  top: -10px;
  right: -10px;
}

#patient-info h5 {
  margin-top: 0;
}
#patient-info div.followup {
  float: left;
  margin-right: 15px;
}
#patient-info div.followup span.inline {
  display: inline-block;
}
#patient-info .input {
  display: none;
}
#patient-info .bootstrap-datetimepicker-widget {
  margin-top: -13px;
}

#patient-info tr.input .input-group-addon,
#patient-info tr.input .input-group-btn,
#patient-info tr.input .input-group .form-control {
  display: inline;
}
#patient-info tr.input input,
#patient-info tr.input select,
#patient-info tr.input textarea {
  width: 220px;
  display: inline;
}
#patient-info tr.input .date {
  display: inline-block;
}
#patient-info tr.input .date input {
  width: 182px;
}
#patient-info tr.input label.inline input {
  width: auto;
}
#patient-info tr.input .input-mini {
  width: 60px !important;
}
#patient-info tr.input .input-small {
  width: 90px !important;
}
#patient-info tr.input .input-medium {
  width: 150px !important;
}

.column {
  width: 25%;
  float: left;
  margin: 10px 0;
}
.column h3 {
  line-height: 20px;
  padding-top: 0;
  padding-left: 10px;
}
.column ul {
  margin: 0;
}
.column ul li {
  padding-left: 10px;
  list-style-type: none;
}
#feature .column p {
  width: 90%;
}

#board {
  margin-top: 20px;
}
#board dd {
  margin-bottom: 20px;
}
.lead {
  margin-top: 20px;
}

#overview,
#citing,
#legal,
#sidebar ul li,
#ddd_docs ul li,
#ddd_news ul li,
#ddd_faqs,
#legal_content,
.featurette,
#join-info,
#data_sharing {
  line-height: 24px;
}

.faqs {
  padding-left: 5px;
}
.faqs > h3 {
  margin-bottom: 15px;
}
.faqs > h5 {
  line-height: 27px;
  cursor: pointer;
}

#legal h3 {
  margin-top: 20px;
  margin-bottom: 15px;
}

#search {
  padding-top: 5px;
  max-width: 30%;
}
#search .span2 {
  width: 270px;
  padding-left: 15px;
  margin-left: 20px;
}

#search-examples {
  margin-bottom: 20px;
}
#search-examples ul {
  margin-top: 7px;
}
#search-examples ul li a {
  width: 165px;
  display: inline-block;
}
#search-examples ul li span {
  margin-left: 10px;
}

.reset-passwd-tip {
  padding-top: 10px;
}

#add_samples input {
  height: 30px;
}

select {
  width: 220px;
  padding-bottom: 20px;
}

select {
  display: inline-block;
  height: 25px;
  line-height: 20px;
  margin-bottom: 10px;
  padding: 4px 6px;
  vertical-align: middle;
}

input[type="file"] {
  display: inline-block;
}

.lead {
  margin-top: 15px;
  margin-bottom: 15px;
}

.input-group-fix {
  .form-control {
    z-index: 0;
  }
}

.input-group-addon {
  top: 0px;
}
.input-group-addon .required {
  height: 10px;
}

#selected-projects {
  margin-top: 5px;
}
#selected-projects .remove {
  margin-left: 3px;
  cursor: pointer;
}

.feedback {
  cursor: pointer;
  position: fixed;
  bottom: 50%;
  right: -30px;
  z-index: 10;
  -moz-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
}

/* Required field START */

.required {
  position: relative;
}

.required .required-icon {
  display: inline-block;
  vertical-align: middle;
  margin: -0.25em 0.25em 0em;
  padding: 0.5em 0.8em;
}

.required .required-icon {
  position: absolute;
  top: 0em;
  right: 0em;
  z-index: 10;
  margin: 0em;
  width: 30px;
  height: 30px;
  padding: 0em;
}

.required .required-icon:after {
  position: absolute;
  content: "";
  right: 15px;
  top: 0px;
  z-index: -1;
  width: 0em;
  height: 0em;
}

.required select {
  padding-right: 30px;
}

.required .text {
  padding-left: 2px;
}

/* Required field END */

.icon-list li {
  padding: 0 0 5px 20px;
  display: block;
  position: relative;
}
.icon-list li:before {
  position: absolute;
  left: 0px;
  top: 2px;
}
.chevron-right li:before {
  content: "\e080";
}

.patient-header {
  position: relative;
  padding-bottom: 10px;
}
.print-report {
  position: absolute;
  top: 0;
  right: 0;
}
.print-report .glyphicon {
  font-size: 12px;
}

.label {
  &-default {
    background-color: $colour-dusty-gray;
  }
}

#join_new .text-muted {
  margin-top: 10px;
}

ul.gene-panels {
  padding: 0;
}

ul.gene-panels > li {
  font-size: 0.9em;
  list-style: none;
}

form.form {
  margin-bottom: 20px;
}

form.form .form-group {
  display: table-row;
  float: left;
  width: 100%;
  margin-bottom: 0;
  border-top: 1px solid #ddd;
}

form.form .form-group > label,
form.form .form-group > div {
  float: left;
  width: 50%;
  display: table-cell;
  padding: 6px 8px;
  vertical-align: top;
}

form.form .form-group > label {
  margin: 0;
  font-weight: normal;
}

form.form:not(.display) .form-group > div {
  padding: 8px 8px;
}

form.form .form-group:nth-child(odd) {
  background: #f9f9f9;
}

form.form .form-group.flex > div {
  display: flex !important;
}

form.form .alert {
  white-space: initial;
  margin: 0 0 20px 0;
}

form.form textarea {
  max-width: 100%;
}

form.form .form-group:hover {
  background-color: rgb(245, 245, 245);
}

/* Prevent pointer events from firing on links and buttons which have a 'data-
 * action' attribute until the 'initialised' class has been applied to the
 * body. This ensures that the javascript has a chance to override the
 * handlers before they're clicked.
 */

button[data-action],
.btn[data-action],
a[data-action] {
  pointer-events: none !important;
}

body.initialised button[data-action],
body.initialised .btn[data-action],
body.initialised a[data-action] {
  pointer-events: auto !important;
}

#editor_holder_curated_data .form-group label {
  width: 100%;
  font-weight: bold;
  margin: 0 0 5px 0;
  display: inline-block;
  float: none;
  padding: 0;
}

#editor_holder_curated_data .form-group {
  margin-bottom: 15px;
  display: block;
  background: none;
  border-top: 0;
  float: none;
}
#editor_holder_curated_data h3 {
  font-size: 18px;
}

/* Fix tipsy tooltips appearing beneath modal dialogues. Gross */
.tipsy {
  z-index: 100000 !important;
}

.eula-agree-btn-container {
  display: inline-block;
}

.tabset {
  display: flex;
  flex-direction: column;
}

.header {
  &-container {
    cursor: default;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }

  &-description {
    margin-left: $spacing-x-small;
  }
}

#project-tabs {
  display: flex;
  flex-direction: column;

  .header-description {
    font-weight: normal;
    overflow: hidden;
    line-height: 1;
    max-width: 300px;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #999;
  }
}

/** Fix the maximum width of a variant panel cell **/
.variant-panel table.table tbody tr td + td {
  max-width: 248px;
  overflow: hidden;
  text-overflow: ellipsis;
}
