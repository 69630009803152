/* Gene labels row */
.gene-row td div {
  cursor: pointer;
}
span.label-gene {
  font-size: 14px;
  font-weight: 780;
}
div.generic-filters,
div.gene-labels {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none !important;
}

span.label-gene {
  float: left;
  cursor: pointer;
  padding-bottom: 0.4em;
  margin-right: 5px;
  margin-bottom: 5px;
}
span.label-gene {
  background-color: #428bca;
  border: solid #428bca 2px;
}
span.label-gene:hover {
  background-color: #5ea9eb;
}
span.label-gene.collapsed {
  background-color: #999;
}

span.label-gene.collapsed {
  display: none !important;
}
span.label-gene.collapsed:hover {
  background-color: #bababa;
}
span.label-gene.is_read {
  background: #85aacd;
  border-color: #85aacd;
  font-weight: normal !important;
}
span.label-gene sup {
  padding: 1px 4px 2px;
  margin-left: 5px;
  border-radius: 2px;
}
span.label-gene:not(.empty) sup {
  background-color: #ffffff;
  color: #333333;
}
div.gene-labels > span.label-gene.empty,
div.gene-labels > span.label-gene.filtered {
  display: none;
}
div.gene-labels.expanded > span.label-gene.empty:not(.filtered),
span.label-gene.selected {
  display: inline-block !important;
}

span.label-gene.collapsed sup {
  background-color: #eccb9e;
}
span.label-gene.label-vus {
  background-color: #ffb96a !important;
  border: solid #ffb96a 2px !important;
}
span.label-gene.label-likely-pathogenic {
  background-color: #ff7400 !important;
  border: solid #ff7400 2px !important;
}
span.label-gene.label-clearly-pathogenic {
  background-color: #ff0000 !important;
  border: solid #ff0000 2px !important;
}
span.label-gene.label-excluded {
  background-color: #a7a7a7 !important;
  border: solid #a7a7a7 2px !important;
}

span.label-gene.selected {
  background-color: #204463 !important;
  border: solid #204463 2px;
}

a.toggle-gene-labels-expand {
  float: left;
  cursor: pointer;
  color: #999;
  font-weight: bold;
}

a.toggle-gene-labels-expand:hover {
  color: #428bca;
}

a.toggle-gene-labels-expand:before,
tr.gene-variant-spacer.hidden-variants > td:before {
  font-family: "Glyphicons Halflings";
  float: left;
  padding-right: 5px;
}
div.gene-labels a.toggle-gene-labels-expand:before,
.gene-body tr.gene-variant-spacer.hidden-variants > td:before {
  content: "\e080"; /* Right arrow */
}
div.gene-labels.expanded a.toggle-gene-labels-expand:before,
.gene-body.expanded tr.gene-variant-spacer.hidden-variants > td:before {
  content: "\e079"; /* Left arrow */
}

span.btn.label-vus {
  color: #ffb96a !important;
}
span.btn.label-likely-pathogenic {
  color: #ff7400 !important;
}
span.btn.label-clearly-pathogenic {
  color: #ff0000 !important;
}

span.btn.hide-pathogenicity {
  color: #7a82ff !important;
}

/* Reset, expand variant genes, expand all genes buttons */
.link-filter {
  color: #333;
  padding-right: 8px;
  cursor: pointer;
  font-size: 0.9em;
}
.link-filter:first-child {
  border-right: solid #999 1px;
  margin-right: 8px;
}
.link-filter:last-child {
  padding-right: 0;
}
.link-filter i {
  padding-left: 3px;
}
.btn-filter {
  padding-left: 6px !important;
  padding-right: 6px !important;
  /*background-color: #428BCA;
    color: #ffffff;*/
}

.btn-filter i {
  margin-left: 4px;
  margin-right: 4px;
  /*color: #f0ad4e;*/
}

.gene-table
  > tbody.gene-body.selected
  > tr.gene-row
  > td:first-child:hover
  span.label-gene {
  background-color: #f0ad4e !important;
}

.gene-table > tbody.gene-body.selected > tr.variant-row.selected > td a:hover {
  color: #f0ad4e !important;
}

span.clear-input {
  position: absolute;
  right: 5px;
  top: 0;
  bottom: 0;
  height: 14px;
  margin: auto;
  font-size: 14px;
  cursor: pointer;
  color: #ccc;
}

span.clear-input:hover {
  color: #555;
}

span.gene-preferred-transcript {
  margin-left: 7px;
}
.gene-description {
  font-style: italic;
}
.gene-hi-indicator {
  height: 18px;
  width: 18px;
  display: block;
  float: left;
  border: solid #eeeeee 1px;
  margin: 0 3px;
}
span.panel-name {
  display: inline-block;
  white-space: nowrap;
}
span.label-inheritance {
  margin-right: 2px;
  margin-bottom: 2px;
  float: left;
  padding: 4px 8px;
}
div.panel-names,
p.inheritance-modes {
  display: inline-block;
  margin-bottom: 2px;
}
span.label-inheritance {
  background-color: #8c2c31;
}
span.label-inheritance.autosomal.recessive {
  background-color: #f0ad4e;
}
td.variant-placeholder,
tbody.gene-placeholder td {
  background: none !important;
  color: #b0b0b0 !important;
  font-style: italic;
}
tr.variant-row {
  height: 1px;
  cursor: pointer;
  border-top: solid #ddd 1px;
}

tr.variant-row td span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
tr.variant-row td:hover,
tr.variant-row td span:hover {
  overflow: visible;
}
tr.variant-row > td {
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: initial;
  padding-bottom: 0 !important;
}
tr.variant-row > td > p span {
  display: block;
}
tr.gene-variant-spacer td {
  border-top: none !important;
  background: none !important;
  cursor: pointer;
  text-indent: -10000px;
  overflow: hidden;
}
tbody.selected tr.gene-variant-spacer td {
  text-indent: 0;
  overflow: none;
  color: #999;
}
tbody.selected tr.gene-variant-spacer td:hover {
  text-decoration: underline;
  color: #428bca;
}

td.variant-placeholder {
  height: 1px;
}

span.variant-transcript {
  display: block;
}

tbody.gene-body table td {
  padding-left: 5px;
  padding-right: 5px;
}

tr.variant-row table td {
  vertical-align: top;
}

tr.variant-row table tr.preferred-transcript td {
  font-weight: bold;
}

tr.variant-row.filtered {
  display: none;
}

tbody.gene-body.selected.expanded tr.variant-row.filtered {
  display: table-row;
}

div.expanded .caret {
  content: "";
  border-top: 0;
  border-bottom: 4px solid;
}

tr.variant-row p.toggle-transcripts {
  display: inline-block;
  color: #999;
}
tr.variant-row:hover p.toggle-transcripts {
  color: #474747;
}

tr.variant-row.selected p.toggle-transcripts {
  color: white;
}

tr.variant-row.variant-row-excluded.selected p.toggle-transcripts {
  color: #003b59;
}
tr.variant-row.variant-row-excluded.selected:hover p.toggle-transcripts {
  color: #00121b;
}

tr.variant-row p.toggle-transcripts:hover {
  color: #428bca;
  text-decoration: underline;
}
tr.variant-row.selected p.toggle-transcripts {
  color: white;
}

tr.variant-row p.toggle-transcripts span.caret {
  margin-right: 5px;
}
