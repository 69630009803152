.upload-progress {
  display: inline-block;
  line-height: initial;
  margin-bottom: $spacing-inline-small;
  width: auto;

  &.success {
    .progress-bar-percentage {
      background-color: $upload-progress-success-colour; // #51ab00;
    }
  }

  &.error {
    .progress-bar-percentage {
      background-color: $colour-red;
    }
  }

  .filename {
    font-size: 11px;
    margin: 0 8px 0 4px;
    padding-right: 12px; // To compensate the QC icon
  }

  .icon {
    font-size: 12px;
    top: 0;

    &.success {
      color: $upload-progress-success-colour;
    }

    &.error {
      color: $colour-red;
    }

    &.qc-status {
      margin-left: -12px; // Compensating the .filename padding-right
    }
  }

  .progress-bar-percentage {
    .icon {
      color: $colour-white;
    }
  }
}
