.sequence-variants-summary {
  .pane {
    min-width: 0;
    &-row {
      &-value {
        overflow: hidden;
        text-overflow: ellipsis;
        div {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}