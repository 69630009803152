.has-error .react-select__control {
  border-color: #a94442;
}

.has-warning .react-select__control {
  border-color: #28a745;
}

fieldset[disabled] {
  .react-select {
    &-container {
      pointer-events: none;
    }
    &__control {
      background-color: $colour-light-grey;
    }
    &__clear-indicator {
      display: none;
    }
  }
}
