@use "sass:math";

.patients {
  &-action {
    text-align: center;
    display: block;
    margin: 0 auto;
    &-reports{
      white-space: pre;
    }
    &-header{
      width: 100px
    }
  }
  &-pane {
    .custom-filter .checkbox{
      margin: 0;
    }
    &-row {
      &-header {
        align-items: baseline;
        padding: 0 0 $spacing-inline-medium 0;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        &-filter {
          margin-left: $spacing-inline-medium;
          max-width: math.div($spacing-small-width, 3);
        }
      }
      &-action {
        padding: $spacing-inline-medium 0 $spacing-inline-medium 0;
        &-btn {
          margin-right: $spacing-inline-medium;
        }
      }
    }
  }
}




