@import "src/scss/_tools/variables/general/spacing";
@import "src/scss/_tools/variables/general/colours";
@import "src/scss/_tools/variables/general/fonts";

.fileUploader {
  display: flex;
  flex-direction: column;
  gap: $spacing-small;

  &__drop {
    height: 100px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-color: $colour-grey-300;
    border-style: dashed;

    &__active{
      border: $spacing-border-width-large dashed $colour-lochmara;
      opacity: 0.6;
    }

    &__indicator {
      font-size: $font-size-body-medium * 2;
      color: $colour-lochmara;
    }

    &__label {
      font-size: $font-size-body-medium;
    }
  }

  &__file {
    align-self: flex-start;
  }

}
