.btn {
  &--small {
    padding: 0 $base-inline-spacing;
  }

  &--centred {
    vertical-align: middle;
  }

  &-link {
    &:focus,
    &:active,
    &:focus:active {
      outline: none;
    }
  }

  &-group--with-margin {
    margin: $spacing-inline-small;
  }
}

.text-muted .btn-link {
  font-size: 85%;
  padding: 0;
}

.navbar-nav .btn-link {
  padding: 10px 15px;
}

.button-panel {
  margin-top: 1em;
}