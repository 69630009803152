$list-of-roles: (
    name: "authoriser",
    color: $roles-authoriser-background-colour
  ),
  (
    name: "analyst",
    color: $roles-analyst-background-colour
  ),
  (
    name: "second-analyst",
    color: $roles-second-analyst-background-colour
  ),
  (
    name: "gene-panel-curator",
    color: $roles-gene-panel-curator-background-colour
  ),
  (
    name: "administrator",
    color: $roles-administrator-background-colour
  ),
  (
    name: "clinical-data-curator",
    color: $roles-clinical-data-curator-background-colour
  ),
  (
    name: "interpreted-genome-submitter",
    color: $roles-interpreted-genome-submitter-background-colour
  );

.role {
  @each $role in $list-of-roles {
    $name: map-get($role, "name");

    &-#{$name} {
      background-color: map-get($role, "color") !important;
    }
  }
}
