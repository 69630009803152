.patient-layout {
  position: relative;

  &-header {
    display: flex;
    width: 100%;
    margin: $spacing-small 0;
    justify-content: space-between;

    @media (max-width: $screen-sm-max) {
      flex-direction: column;
    }
  }

  &-breadcrumbs.breadcrumbs {
    margin: 0;
  }

  &-status {
    margin-left: $spacing-small;

    @media (max-width: $screen-sm-max) {
      margin: $spacing-small 0 0;
    }
  }

  .nav-tabs {
    @media (max-width: $screen-sm-max) {
      .pull-left + .pull-right {
        clear: left;
      }
    }
  }
}
