// Default variables for help
// $help-{subclass?}-{state?}-{css property}

$help-background-colour: $colour-cream;
$help-foreground-colour: $colour-black;

$help-button-background-colour: $colour-pizazz;
$help-button-foreground-colour: $colour-white;
$help-tutorial-button-height: 2rem;

$help-spacing-inline: $spacing-small $spacing-inline-small;

// $help-tutorial-control-border-radius: $spacing-border-radius-large;

$help-tutorial-control-width: 300px;

$help-tutorial-control-progress-bullet-diameter: 0.8rem;
$help-tutorial-control-progress-bullet-background-colour: $colour-pizazz;

$help-tutorial-control-bottom: 2rem;
$help-tutorial-control-progress-top: -3rem;

$help-z-index: 40;
