body {
  font-family: "Helvetica Neue", Helvetica, "freesans", sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  min-width: 600px;
}

.green {
  color: green;
}
.red {
  color: red;
}
.black {
  color: black;
}
.purple-grey {
  color: #9999bb;
}

table.table thead th {
  background-color: #bcc5cc !important;
}

/* Fix for bootstrap */

.table > thead > tr > td.active,
.table > tbody > tr > td.active,
.table > tfoot > tr > td.active,
.table > thead > tr > th.active,
.table > tbody > tr > th.active,
.table > tfoot > tr > th.active,
.table > thead > tr.active > td,
.table > tbody > tr.active > td,
.table > tfoot > tr.active > td,
.table > thead > tr.active > th,
.table > tbody > tr.active > th,
.table > tfoot > tr.active > th {
  background-color: #f9f9f9;
}

table.data-table tr.active td,
table.data-table tr.active td a,
.data-table tr.active td,
.data-table tr.active td a {
  color: #ffffff;
}
table.data-table tr.active td .btn .glyphicon,
data-table tr.active td .btn .glyphicon {
  color: #333333;
}
table.data-table tr.active td,
.data-table tr.active td {
  background-color: #0088cc !important;
}

.table-hover > tbody > tr > td.active:hover,
.table-hover > tbody > tr > th.active:hover,
.table-hover > tbody > tr.active:hover > td,
.table-hover > tbody > tr.active:hover > th {
  background-color: transparent;
}
.nav-tabs a.active {
  background-color: #0088cc !important;
  color: #ffffff;
  border-bottom: 1px solid #0088cc !important;
  border-left: 1px solid #0088cc !important;
}

#genotype > .tab-content {
  border: 0;
}

div.dataTables_scrollHead table {
  border-bottom: 0 !important;
}
div.dataTables_scrollHead th {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
div.dataTables_scrollBody table {
  border-top: 0 !important;
}

.btn-group-hover {
  opacity: 0;
}
tr:hover .btn-group-hover {
  opacity: 1;
}

table thead {
  .sorting, .sorting_asc, .sorting_desc {
    padding-right: 21px !important;
  }
}

table.data-table thead .sorting {
  background: url("/images/data-table/sort_both.png") no-repeat center right;
}
table.data-table thead .sorting_asc {
  background: url("/images/data-table/sort_asc.png") no-repeat center right;
}
table.data-table thead .sorting_desc {
  background: url("/images/data-table/sort_desc.png") no-repeat center right;
}
table.data-table thead .sorting_asc_disabled {
  background: url("/images/data-table/sort_asc_disabled.png") no-repeat center
    right;
}
table.data-table thead .sorting_desc_disabled {
  background: url("/images/data-table/sort_desc_disabled.png") no-repeat center
    right;
}

table.data-table-ajax thead .sorting {
  background: url("/images/data-table/sort_both.png") no-repeat center right;
}
table.data-table-ajax thead .sorting_asc {
  background: url("/images/data-table/sort_asc.png") no-repeat center right;
}
table.data-table-ajax thead .sorting_desc {
  background: url("/images/data-table/sort_desc.png") no-repeat center right;
}
table.data-table-ajax thead .sorting_asc_disabled {
  background: url("/images/data-table/sort_asc_disabled.png") no-repeat center
    right;
}
table.data-table-ajax thead .sorting_desc_disabled {
  background: url("/images/data-table/sort_desc_disabled.png") no-repeat center
    right;
}

.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td {
  border-bottom-width: 1px;
}

.table tr.alternative td {
  border-top: 0;
}

table .label-role {
  float: left;
}

.alert-danger,
.alert-error {
  background-color: #f2dede;
  color: #b94a48;
}

.flex {
  display: flex;
}

.bold {
  font-weight: bold;
}

.loading-msg {
  text-align: center;
}

/* Fix tooltip left positioning and stop Genoverse tooltips overlapping */

.tooltip.left .tooltip-arrow {
  border-width: 5px 0 5px 6px;
}

/* HPO -> phenotype table drag and drop */
.dataTables_scroll.ui-droppable {
  border: 1px solid #fff;
  border-radius: 4px;
}
.drop-hover {
  border: 1px solid green !important;
}
a.hpo-tree.ui-draggable-dragging {
  background: #fff;
  border: 1px solid #005580;
}

#hpo-tree ul.tree li .add button {
  border: 0;
}
#hpo-tree ul.tree li .add .icon-user {
  background: url("/images/glyphicons-user.png") no-repeat;
}
#hpo-tree ul.tree li .add .icon-woman {
  background: url("/images/glyphicons-woman.png") no-repeat;
}
#hpo-tree ul.tree li .add .icon-man {
  background: url("/images/glyphicons-man.png") no-repeat;
}
#hpo-tree ul.tree .highlight {
  background: #ffd700;
}
#hpo-tree .glyphicon {
  color: black;
  font-size: 12px;
}
#hpo-tree .term-code {
  color: darkgray;
}

#hpo-search .input-group .form-control:not(:first-child):not(:last-child) {
  border-radius: 4px 0px 0px 4px;
}

#phenotype-tables .nav-tabs a.add-to {
  color: #468847 !important;
  background-color: #dff0d8 !important;
  border-color: #d6e9c6 !important;
}

.genoverse .track_container div.track_controls > select {
  text-align: left;
}

.karyotype .chr .gv_wrapper {
  border: 0;
}
.karyotype .chr .label_container {
  border: 0;
  background: none;
  box-shadow: none;
}
.karyotype .chr .label_container li {
  box-shadow: none !important;
}
.karyotype .chr .label_container li .name {
  color: black;
  text-shadow: none;
  font-size: 10px;
}

#search-results .browser .canvas_container .gv_karyotype_container {
  border-right: 1px solid black;
}
#search-results .browser .canvas_container .gv_wrapper {
  border-right: 1px solid black;
}
#search-results .browser .canvas_container .label_container {
  border: 0;
  background: none;
  box-shadow: none;
}
#search-results .browser .canvas_container .label_container li {
  box-shadow: none !important;
}
#search-results .browser .canvas_container .label_container li .name {
  color: black;
  text-shadow: none;
}

.tooltip.left .tooltip-arrow {
  border-width: 5px 0 5px 6px;
}

.gv_menu table {
  border-collapse: separate;
}
.gv_menu a {
  text-decoration: underline;
}

#bulk-upload-preview .errors,
#bulk-upload-preview .error select,
#bulk-upload-preview .error input {
  background: #f2dede !important;
}
#bulk-upload-preview .errors {
  border: 1px solid black;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
}

.photo-holder .btn {
  border-radius: 500px;
}

#patient-info .table-striped > tbody > tr.odd > td,
#patient-info .table-striped > tbody > tr.odd > th {
  background-color: #f9f9f9;
}
#patient-info .table-striped > tbody > tr.even > td,
#patient-info .table-striped > tbody > tr.even > th {
  background-color: inherit;
}
#patient-info .table-hover > tbody > tr:hover > td,
#patient-info .table-hover > tbody > tr:hover > th {
  background-color: #f5f5f5;
}

/* Patient overview: CNV Reference Panel styles START */

form.form .form-group {
  &:nth-child(odd) {
    .accordion .panel {
      background-color: #fff;
    }
  }

  .accordion {
    border-radius: 8px;
    margin-top: 6px;
    margin-bottom: 10px;
    width: 40em;

    .panel {
      background-color: #f9f9f9;
      padding: 0 10px;
      font-size: 13px;
      overflow: hidden;

      &-heading {
        background-color: transparent;
        padding: 10px 0;

        a {
          text-decoration: none;
          color: #000;
        }
      }

      &-title {
        font-size: 14px;
      }

      &-body {
        padding: 0 0 10px 0;
        color: #555;
      }
    }

    .patient-list {
      padding-top: 10px;
    }

    .card-body {
      .patient-list:first-child {
        border-top: 1px solid #d6d6d6;
      }
    }

    h5 i {
      float: right;
      color: #d6d6d6;
    }
  }
}

/* Patient overview: CNV Reference Panel styles END */

.tab-pane > ul {
  background-color: #0088cc;
  border: 1px solid #0088cc;
}
.tab-pane > .nav-tabs > li > a,
.tab-pane > .nav-tabs > li > a:focus,
.tab-pane > .nav-tabs > li > a:active,
.tab-pane > .nav-tabs > li > a:hover {
  border: 0px;
  border-radius: 4px;
  color: #fff;
  margin: 4px 2px;
  padding: 8px 18px;
}
.tab-pane > .nav-tabs > li > a.active,
.tab-pane > .nav-tabs > li > a:hover {
  background-color: #369df7 !important;
}

div.tabset > h4 {
  margin: 17px 15px 0 15px;
  float: right;
}

/* Nested tabsets */
/* Level 1 */
.tabset .tab-content > div > :first-child.tabset > ul {
  background-color: #0088cc;
  border: 1px solid #0088cc;
}
.tabset .tab-content > div > :first-child.tabset > .nav-tabs {
  margin-top: -20px;
  margin-bottom: 0;
}
.tabset .tab-content > div > :first-child.tabset > .nav-tabs > li > a {
  margin: 3px 5px 0px 3px;
}
.tabset .tab-content > div > :first-child.tabset > .nav-tabs > li > a,
.tabset .tab-content > div > :first-child.tabset > .nav-tabs > li > a:focus,
.tabset .tab-content > div > :first-child.tabset > .nav-tabs > li > a:active,
.tabset .tab-content > div > :first-child.tabset > .nav-tabs > li > a:hover {
  border: 0px;
  border-radius: 4px;
  color: #fff;
  margin: 4px 2px;
  padding: 8px 18px;
}
.tabset .tab-content > div > :first-child.tabset > .nav-tabs > li > a.active,
.tabset .tab-content > div > :first-child.tabset > .nav-tabs > li > a:hover {
  background-color: #369df7 !important;
}
.tabset .tab-content > div > :first-child.tabset > .tab-content {
  margin-top: 1px;
  padding-top: 0;
}

/* Level 2 */
.tabset
  .tab-content
  > div
  > :first-child.tabset
  .tab-content
  > div
  > :first-child.tabset
  > ul {
  background-color: #fff;
  border: 0;
  border-bottom: 1px solid #ddd;
  clear: both;
  margin-bottom: 10px !important;
}
.tabset
  .tab-content
  > div
  > :first-child.tabset
  .tab-content
  > div
  > :first-child.tabset
  > .nav-tabs {
  margin-top: -20px;
  margin-bottom: 0;
}
.tabset
  .tab-content
  > div
  > :first-child.tabset
  .tab-content
  > div
  > :first-child.tabset
  > .nav-tabs
  > li
  > a {
  border: 0 !important;
  border-bottom: solid #999 4px !important;
  border-radius: 0;
  color: #999;
  font-weight: bold;
  background-color: white !important;
  margin-bottom: 0;
}

.tabset
  .tab-content
  > div
  > :first-child.tabset
  .tab-content
  > div
  > :first-child.tabset
  > .nav-tabs
  > li
  > a.active,
.tabset
  .tab-content
  > div
  > :first-child.tabset
  .tab-content
  > div
  > :first-child.tabset
  > .nav-tabs
  > li
  > a:focus,
.tabset
  .tab-content
  > div
  > :first-child.tabset
  .tab-content
  > div
  > :first-child.tabset
  > .nav-tabs
  > li
  > a:hover,
.tabset
  .tab-content
  > div
  > :first-child.tabset
  .tab-content
  > div
  > :first-child.tabset
  > .nav-tabs
  > li
  > a:active {
  border-bottom: solid #428bca 4px !important;
  color: #428bca;
}
.tabset
  .tab-content
  > div
  > :first-child.tabset
  .tab-content
  > div
  > :first-child.tabset
  > .nav-tabs
  > li
  > a.active
  span.label,
.tabset
  .tab-content
  > div
  > :first-child.tabset
  .tab-content
  > div
  > :first-child.tabset
  > .nav-tabs
  > li
  > a:focus
  span.label,
.tabset
  .tab-content
  > div
  > :first-child.tabset
  .tab-content
  > div
  > :first-child.tabset
  > .nav-tabs
  > li
  > a:hover
  span.label,
.tabset
  .tab-content
  > div
  > :first-child.tabset
  .tab-content
  > div
  > :first-child.tabset
  > .nav-tabs
  > li
  > a:active
  span.label {
  background-color: #428bca;
}

.tabset
  .tab-content
  > div
  > :first-child.tabset
  .tab-content
  > div
  > :first-child.tabset
  > .tab-content {
  margin-top: 1px;
  padding-top: 0;
}

.column a {
  text-decoration: none;
}
.column h3 {
  font-size: 12px;
  font-weight: bold;
  color: #313131;
  border-bottom: 1px solid #d0d0d0;
}
.column ul li {
  background: none;
}

#rhs ul.bullet li,
.manuelTwo > li,
.pdfs > li {
  background: none;
}

.glyphicon-trash {
  font-size: 11px;
}

#search-examples ul li a {
  font-weight: bold;
  font-style: italic;
}

.reset-passwd-tip {
  font-size: 11px;
}

.btn-primary {
  background-color: #0088cc;
}

#add_samples input,
#login input {
  border-radius: 4px 4px 4px 4px;
}

.ddg2p a,
.omim a,
.morbid a {
  text-decoration: none;
}

.input-group #message {
  border-radius: 4px 4px 4px 4px;
}
.input-group-addon .required {
  color: red;
}
.input-group .input-group-addon {
  font-size: 12px !important;
}

.modal-footer {
  background-color: #f5f5f5;
  border-radius: 0 0 6px 6px;
  border-top: 1px solid #dddddd;
  box-shadow: 0 1px 0 #ffffff inset;
  text-align: right;
}

/* Required field START */

.required .required-icon {
  background-color: #e8e8e8;
  border-color: #e8e8e8;
  color: rgba(0, 0, 0, 0.65);
  text-transform: uppercase;
  font-weight: normal;
  border-radius: 0.325em;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: background 0.1s linear;
  -moz-transition: background 0.1s linear;
  transition: background 0.1s linear;
  font-size: 75%;
}

.required .required-icon {
  background-color: transparent;
  text-align: center;
  -webkit-transition: color 0.2s ease;
  -moz-transition: color 0.2s ease;
  transition: color 0.2s ease;
}

.required .required-icon:after {
  content: "";
  border-top: 0em solid transparent;
  border-right: 30px solid transparent;
  border-bottom: 30px solid transparent;
  border-left: 0em solid transparent;
  border-right-color: inherit;
  -webkit-transition: border-color 0.2s ease;
  -moz-transition: border-color 0.2s ease;
  transition: border-color 0.2s ease;
}

.required-icon.text {
  color: #b80000;
  font-size: 23px;
  text-align: left;
}
/* Required field END */

.icon-list li:before {
  font-family: "Glyphicons Halflings";
  font-size: 65%;
}
.chevron-right li:before {
  content: "\e080";
}

.nav-tabs > li > a {
  color: #333333;
  transition: all 0.2s ease-in-out 0s;
  border-radius: 4px 4px 0 0;
  border: 1px solid #e7e7e7;
}

.navbar-inverse .navbar-nav > .open > a,
.navbar-inverse .navbar-nav > .open > a:hover,
.navbar-inverse .navbar-nav > .open > a:focus {
  background-color: rgba(0, 0, 0, 0);
  color: #333333;
}

.navbar-inverse .navbar-nav > li > a:hover,
.navbar-inverse .navbar-nav > li > a:focus {
  background-color: rgba(0, 0, 0, 0);
  color: #333333;
}

.faqs > h3 {
  color: #8e0018;
}
.faqs > h5 {
  color: #0088cc;
}

.dropdown-menu.inner.selectpicker > li > a:hover {
  background-color: #0088cc;
  color: #ffffff;
}

#selected-projects {
  font-size: 10px;
}
#selected-projects .name {
  font-style: italic;
}
#selected-projects .remove {
  font-size: 7px;
}

@font-face {
  font-family: "Glyphicons Halflings";
  src: url("/fonts/glyphicons-halflings/glyphicons-halflings-regular.eot");
  src: url("/fonts/glyphicons-halflings/glyphicons-halflings-regular.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/glyphicons-halflings/glyphicons-halflings-regular.woff")
      format("woff"),
    url("/fonts/glyphicons-halflings/glyphicons-halflings-regular.ttf")
      format("truetype"),
    url("/fonts/glyphicons-halflings/glyphicons-halflings-regular.svg#glyphicons_halflingsregular")
      format("svg");
}

#join_new .text-muted {
  font-size: 10px;
}

label.checkbox,
.dataTables_filter label {
  font-weight: normal;
}

.text-right {
  text-align: right !important;
}
.text-left {
  text-align: left !important;
}
.text-center {
  text-align: center !important;
}

form.form .outer-form-group {
  display: table-row;
  float: left;
  width: 100%;
  margin-bottom: 0;
}

.home-feature {
  margin: 40px 0 0 0;
}
.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}
.home-feature-col {
  font-size: 0.9em;
  background-color: #fafafa;
  height: 230px;
  padding: 20px 20px 15px 20px;
  border-bottom: solid #cccccc 1px;
  color: #575757;
}
.home-feature-col h2 {
  font-size: 1.7em;
  color: #333333;
  margin: 0 0 10px 0;
  padding: 0;
}
.home-feature-col h2 strong {
  color: #0088cc;
}
.home-feature-col h3 {
  font-size: 1em;
}
.home-feature-sapientia {
  margin-right: 4px;
  background-image: url("/images/home/feature_screen.png");
  background-repeat: no-repeat;
  background-position: center 100px;
}
.home-feature-congenica {
  margin-right: 4px;
  background-image: url("/images/home/genome_campus.png");
  background-repeat: no-repeat;
  background-position: center 45px;
}
.home-feature-news {
  background-image: url("/images/home/bands.png");
  background-repeat: no-repeat;
  background-position: 100px 50px;
}
.home-feature-news a {
  display: block;
  margin: 0 0 10px 25px;
  text-indent: -10px;
}
.home-feature-news a i {
  padding-right: 5px;
}

table.table-dictionary td:last-child {
  font-weight: bold;
}

table.table-dictionary td.single-column div:first-child {
  font-weight: normal;
  margin-bottom: 4px;
}

table.table-dictionary td.single-column .table {
  margin: 0;
}

table.table-dictionary-rev td:first-child {
  font-weight: bold;
}

.tab-content {
  padding: 5px 0 30px 0;
}

.table-filter .dataTables_filter {
  margin-left: 30px;
}

.table-filter .bootstrap-select {
  width: 145px !important;
}

.data-table select {
  margin-bottom: 0;
}

.data-table tr.group {
  background-color: $colour-mercury;
  border-top: 4px solid #d8d8d8;
}

table.data-table thead th {
  background-position: center right;
  vertical-align: bottom;
}

.data-table tr.group td {
  padding: 2px 8px;
  font-weight: bold;
}

/* Shift-enter */
div.shiftenter,
div.shiftenterInactive {
  position: absolute;
  z-index: 1;

  font-size: 0.8em;
  opacity: 0.4;
  filter: alpha(opacity=40);
}
div.shiftenter {
  display: inline-block;
}
div.shiftenterInactive {
  display: none;
}

a.collapsed span.caret {
  -ms-transform: rotate(-90deg); /* IE 9 */
  -webkit-transform: rotate(-90deg); /* Chrome, Safari, Opera */
  transform: rotate(-90deg);
}
a span.caret {
}

/* PFAM protein graphic */
.pfam-protein-graphic {
  overflow: hidden;
  border: solid #eeeeee 1px;
  margin: 0 0 10px 0;
}
.pfam-protein-loading {
  width: 100%;
  text-align: center;
  margin-top: 80px;
  font-weight: bold;
}
.pfam-protein-graphic:hover {
  border: solid #428bca 1px;
}
.pfam-protein-graphic svg {
  margin: 0;
}
.pfam-protein-graphic svg rect {
  cursor: pointer;
}

/* OMIM - 24 September 2014 */
.omim-section-panel-item {
  margin: 0 0 10px 0;
  padding: 0 0 10px 0;
  border-bottom: solid #eeeeee 1px;
}
.omim-section-panel-item p {
  font-size: 0.9em;
}
.omim-section-panel-item a {
  cursor: pointer;
}
a.omim-highlight-reference {
  padding: 4px;
}
a.omim-highlight-reference.active {
  background-color: #428bca;
  color: #ffffff;
}

.omim-reference-item {
  margin: 0 0 20px 0;
  border-left: solid #ffffff 2px;
  clear: both;
}
.omim-reference-item:hover {
  border-left: solid #cccccc 2px;
}
.omim-reference-item.active {
  border-left: solid #428bca 2px;
}
.omim-reference-item-options {
}
.omim-reference-item-content {
}
.omim-reference-title a {
  font-weight: bold;
  cursor: pointer;
}
.omim-reference-title a.collapsed span.caret {
  -ms-transform: rotate(-90deg); /* IE 9 */
  -webkit-transform: rotate(-90deg); /* Chrome, Safari, Opera */
  transform: rotate(-90deg);
}

.omim-reference-abstract {
  font-size: 0.9em;
  color: #999999;
  padding: 0 0 5px 0;
  margin: 5px 0;
  border-bottom: solid #cccccc 1px;
}
.omim-reference-authors {
  font-size: 0.9em;
  font-style: italic;
  margin: 5px 0;
}
.omim-reference-source {
  font-size: 0.9em;
  font-weight: bold;
}

a.omim-reference-flag-link {
  cursor: pointer;
  color: #cccccc;
  display: block;
}
a.omim-reference-flag-link:hover {
  color: #666666;
}
a.omim-reference-flag-link i {
  font-size: 2em;
}
a.omim-reference-flag-link span {
  display: none;
}
a.omim-reference-flag-link.active {
  color: #428bca;
}

a.omim-reference-hyperlink {
  cursor: pointer;
  color: #cccccc;
  display: block;
  margin: 15px 0 0 6px;
}
a.omim-reference-hyperlink:hover {
  color: #428bca;
}
a.omim-reference-hyperlink i {
  font-size: 1.2em;
}

a.omim-reference-title {
  margin: 25px 0 0 0;
}

/* Articles - 26 August 2014 */
.article-list-body {
  padding-bottom: 30em;
}
.article-list-body .article-list-body-item {
  border: solid 1px #eeeeee;
  margin: 0 10px 10px 0;
  min-height: 100px;
}
.article-list-body
  .article-list-body-item:hover
  .article-list-body-item-content {
  border-left: solid #0088cc 2px;
}
.article-list-body-item-options {
  margin: 0;
}
.article-list-body-item-options ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.article-list-body-item-options ul li {
  list-style: none;
  margin: 0;
  padding: 0;
  float: left;
  width: 100%;
  font-size: 0.9em;
  text-align: right;
  margin-bottom: 20px;
  color: #666666;
}
.article-list-body-item-options ul li a {
  display: block;
  cursor: pointer;
  padding: 10px 0 0 0;
  font-weight: bold;
  position: relative;
}
.article-list-body-item-options ul li a i {
  position: absolute;
  left: 0;
  top: 0;
}
.article-list-body-item-options ul li.article-list-body-item-options-flag {
  margin-bottom: 40px;
}
.article-list-body-item-options ul li.article-list-body-item-options-flag a {
  color: transparent;
}
.article-list-body-item-options
  ul
  li.article-list-body-item-options-flag
  a:hover {
  color: #666666;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.article-list-body-item-options ul li.article-list-body-item-options-flag i {
  font-size: 2em;
  color: #cccccc;
}
.article-list-body-item-options
  ul
  li.article-list-body-item-options-flag:hover
  i {
  font-size: 2em;
  color: #666666;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.article-list-body-item-options
  ul
  li.article-list-body-item-options-flag-active
  a {
  color: transparent;
}
.article-list-body-item:hover
  ul
  li.article-list-body-item-options-flag-active
  a {
  color: #cccccc;
}
.article-list-body-item-options
  ul
  li.article-list-body-item-options-flag-active
  a:hover,
.article-list-body-item-options
  ul
  li.article-list-body-item-options-flag-active
  i,
.article-list-body-item-options
  ul
  li.article-list-body-item-options-flag-active:hover
  i {
  color: #0088cc;
}
.article-list-body-item-options
  ul
  li.article-list-body-item-options-flag-active
  i {
  font-size: 2em;
}

.article-list-body-item-content {
  border-left: solid #eeeeee 2px;
  margin: 10px 0;
}
.article-list-body-item-content-title {
  display: block;
  font-weight: bold;
  margin: 0 0 5px 0;
}
.article-list-body-item-content-title:hover {
  text-decoration: underline;
}
.article-list-body-item-content-description {
  margin-bottom: 5px;
  font-size: 0.9em;
}
.article-list-body-item-content-authors {
  font-size: 0.9em;
  margin-bottom: 3px;
}
.article-list-body-item-content-journal {
  font-size: 0.9em;
  margin-bottom: 5px;
}
.article-list-body-item-comments {
  margin-top: 10px;
  border-top: solid 1px #eeeeee;
  background-color: #f9f9f9;
  font-size: 0.9em;
}
.article-list-body-item-comment-item {
  padding: 5px 10px;
}
.article-list-body-item-comment-item-details {
  color: #999999;
}
.article-list-body-item-comment-item
  a.article-list-body-item-comment-item-delete {
  color: #eeeeee;
}
.article-list-body-item-comment-item:hover
  a.article-list-body-item-comment-item-delete {
  color: #cccccc;
}
.article-list-body-item-comment-item:hover
  a.article-list-body-item-comment-item-delete:hover {
  color: #428bca;
}

.article-list-body-item-add-comments {
  background-color: #f9f9f9;
  font-size: 0.9em;
  padding: 5px 10px;
}
.article-list-body-item-add-comments textarea {
  width: 100%;
  height: 2.5em;
  border: solid 1px #eeeeee;
}
/* Side bar affix */
#article-side-bar {
  position: relative;
}
#article-side-bar-nav.affix-top {
  position: relative;
  margin-top: 0px;
  width: 280px;
}
#article-side-bar-nav.affix {
  position: fixed;
  top: 0px;
  width: 280px;
}

/* Side bar */
.article-side-bar ul > li a.collapsed {
  color: #666666;
}
.article-side-bar ul > li a.collapsed b.caret {
  -ms-transform: rotate(-90deg); /* IE 9 */
  -webkit-transform: rotate(-90deg); /* Chrome, Safari, Opera */
  transform: rotate(-90deg);
}

.article-side-bar ul > li a {
  border-left: solid 2px transparent;
  font-weight: bold;
  padding: 8px 15px;
  cursor: pointer;
}
.article-side-bar ul li ul li.active a {
  border-left: solid 2px #0088cc;
  background-color: transparent;
  color: #0088cc;
}
.article-side-bar ul > li a:hover {
  border-left: solid 2px #0088cc;
  background-color: transparent;
  color: #0088cc;
}
/* Overflow for long results */
.article-side-bar ul > li > ul {
  max-height: 400px;
  overflow-y: auto;
}
.article-side-bar ul > li > ul > li > a {
  font-size: 0.9em;
  color: #666666;
  font-weight: normal;
}
.article-side-bar ul > li > ul > li > a:hover {
  color: #0088cc;
}

#article-side-bar-refresh-button {
  border-top: solid #cccccc 1px;
  padding: 0;
  margin-top: 20px;
  position: relative;
}
#article-side-bar-refresh-button #article-gradient-bottom {
  position: absolute;
  border: 0;
  width: 100%;
  height: 25px;
  top: -35px;
  background: -moz-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  ); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, rgba(255, 255, 255, 0)),
    color-stop(100%, rgba(255, 255, 255, 1))
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  ); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  ); /* Opera 11.10+ */
  background: -ms-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  ); /* IE10+ */
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  ); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
}

#article-side-bar-refresh-button a {
  padding: 5px 10px;
  margin: 0 0 0 85px;
  width: 180px;
  border: 0;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  background-color: #666666;
  color: #ffffff;

  -webkit-border-bottom-right-radius: 3px;
  -webkit-border-bottom-left-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -moz-border-radius-bottomleft: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
#article-side-bar-refresh-button a:hover {
  background-color: #0088cc;
  color: #ffffff;
}
#article-side-bar-refresh-button a:hover i {
  -animation: spin 0.9s infinite linear;
  -webkit-animation: spin2 0.9s infinite linear;
}

/* Article search */
#article-search-form-container {
  margin: 0 0 15px 0;
  background-color: #ffffff;
  z-index: 99;
}
#article-search-form-container #article-search-textbox {
  width: 500px;
  height: 30px;
  margin: 0;
  border-top: solid 1px #cccccc;
  border-bottom: solid 1px #cccccc;
  border-left: solid 1px #cccccc;
  padding: 0 4px 0 10px;
}
#article-search-form-container #article-search-button {
  height: 30px;
  width: 80px;
  border-radius: 0;
  border-top: solid 1px #0088cc;
  border-bottom: solid 1px #0088cc;
  border-left: solid 1px #0088cc;
  -webkit-border-top-right-radius: 3px;
  -webkit-border-bottom-right-radius: 3px;
  -moz-border-radius-topright: 3px;
  -moz-border-radius-bottomright: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
#article-search-form-container.affix-top {
  position: relative;
  margin-top: 0;
  width: 870px;
}
#article-search-form-container.affix {
  position: fixed;
  top: 0;
  width: 870px;
  padding: 10px 0 0 0;
  /*  border-bottom : solid 1px #0088CC;*/
}
ul#article-search-source-list {
  list-style: none;
  height: 42px;
  padding: 0;
  margin: 0 0 5px 0;
  border-bottom: solid #eeeeee 1px;
}
#article-search-form-container.affix ul#article-search-source-list {
  border-bottom: solid 1px #999999;
}

ul#article-search-source-list input[type="radio"] {
  display: none;
}
ul#article-search-source-list li {
  float: left;
  display: block;
}
ul#article-search-source-list li label {
  display: block;
  padding: 10px 10px 10px 10px;
  color: #999999;
  font-size: 0.9em;
  cursor: pointer;
}
ul#article-search-source-list li label:hover {
  text-decoration: none;
  color: #428bca;
}
ul#article-search-source-list input[type="radio"]:checked + label {
  border-bottom: solid #428bca 4px;
  color: #428bca;
}
ul#article-search-source-list li.back-to-top a {
  display: none;
  padding: 10px 10px 10px 10px;
  color: #999999;
  font-size: 0.9em;
  cursor: pointer;
  font-weight: bold;
}
ul#article-search-source-list li.back-to-top a:hover {
  color: #428bca;
}
#article-search-form-container.affix
  ul#article-search-source-list
  li.back-to-top
  a {
  display: block;
}
#article-search-results-container {
  min-height: 300px;
}
#article-search-results-container .glyphicon-refresh-animate {
  color: #0088cc;
}
.glyphicon-refresh-animate {
  color: #0088cc;
  -animation: spin 0.7s infinite linear;
  -webkit-animation: spin2 0.7s infinite linear;
}

@-webkit-keyframes spin2 {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: scale(1) rotate(0deg);
  }
  to {
    transform: scale(1) rotate(360deg);
  }
}

#article-search-results-container .search-results-navigation.affix-top {
  position: relative;
  z-index: 99;
  width: 200px;
}
#article-search-results-container .search-results-navigation.affix {
  position: fixed;
  top: 0px;
  width: 200px;
}

#article-search-results-container .search-results-navigation ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
#article-search-results-container .search-results-navigation ul li {
  margin: 0;
  padding: 0;
  float: left;
}
#article-search-results-container .search-results-navigation ul li a {
  display: block;
  text-align: center;
  cursor: pointer;
  width: 40px;
  height: 40px;
  color: #428bca;
  border: solid #428bca 1px;
  background-color: #ffffff;
  margin: 0 4px 4px 0;
  padding-top: 9px;
  text-decoration: none;
}
#article-search-results-container .search-results-navigation ul li a.active,
#article-search-results-container .search-results-navigation ul li a:hover {
  background-color: #428bca;
  color: #ffffff;
  border: solid #428bca 1px;
}

/* Patient sequence variants article */
.snv-article {
  padding-bottom: 15px;
  margin-bottom: 10px;
  border-bottom: solid #ddd 1px;
}

.snv-article > .col-md-4 {
  padding-top: 10px;
}
.article-comments,
.audit-comments {
  margin: 20px 0;
}
.article-comments > dl,
.audit-comments > dl {
  margin: 0 0 5px 0;
  padding: 8px 3px 10px 3px;
  border: solid #ffffff 1px;
}
.article-comments > dl:hover,
.audit-comments > dl:hover {
  border-top: solid #eeeeee 1px;
  border-bottom: solid #eeeeee 1px;
  cursor: pointer;
}
.article-comments > dl > dt,
.audit-comments > dl > dt {
  border-right: solid #ddd 2px;
  padding-right: 10px;
}
.article-comments > dl:hover > dt,
.audit-comments > dl:hover > dt {
  border-right: solid #428bca 2px;
}

.article-comments > dl > dd > a.btn,
.audit-comments > dl > dd > a.btn {
  margin: 0 0 5px 5px;
}
.audit-comments-pubmed {
  padding: 10px 20px;
  border: solid #eeeeee 1px;
}

/* Patient audit */
.audit-items {
  margin: 0 0 20px 0;
}
.audit-item-info .audit-item-date {
  width: 60px;
  float: right;
}

.audit-item-info .audit-item-date-calendar .date {
  display: block;
  text-align: center;
  border: solid #428bca 1px;
  background-color: #ffffff;
  color: #428bca;
  -webkit-border-top-left-radius: 3px;
  -webkit-border-top-right-radius: 3px;
  -moz-border-radius-topleft: 3px;
  -moz-border-radius-topright: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  font-size: 1.5em;
  font-weight: bold;
}
.audit-item-info .audit-item-date-calendar .month {
  display: block;
  text-align: center;
  background-color: #428bca;
  border: solid #428bca 1px;
  color: #ffffff;
  -webkit-border-bottom-right-radius: 3px;
  -webkit-border-bottom-left-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -moz-border-radius-bottomleft: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.audit-item-info .audit-item-date .day {
  display: block;
  text-align: center;
  margin: 4px 0 0 0;
  font-style: italic;
  font-weight: bold;
  color: #999999;
  display: none;
}
.audit-item-details {
  font-size: 0.9em;
  border-left: solid #ffffff 2px;
}
.audit-item-details:hover {
  border-left: solid #428bca 2px;
}
.audit-item-details-user {
  color: #999999;
  font-weight: bold;
  margin: 0 0 3px 0;
}
.audit-item-details-user a.audit-item-delete {
  color: #eeeeee;
}
.audit-item-details:hover .audit-item-details-user a.audit-item-delete {
  color: #cccccc;
}
.audit-item-details-user a.audit-item-delete:hover {
  color: #428bca;
  cursor: pointer;
}
.audit-item-details-user a.audit-item-delete span {
  display: none;
}
.audit-item-details-info {
  padding: 0 0 10px 0;
}

.audit-item-article {
  border: solid #eeeeee 1px;
  margin: 4px 0 4px 2px;
  padding: 0 4px 8px 4px;
}
.audit-item-article-title {
  margin: 8px 0 4px 0;
}
.audit-item-article-title a {
  font-weight: bold;
  cursor: pointer;
}
.audit-item-article-abstract {
  margin: 0 0 8px 0;
  padding: 0 0 4px 0;
  border-bottom: solid #cccccc 1px;
}
.audit-item-article-authors {
  font-style: italic;
}
.audit-item-article-source {
  font-weight: bold;
}
a.audit-item-article-flag-link {
  cursor: pointer;
  color: #cccccc;
  display: block;
}
a.audit-item-article-flag-link:hover {
  color: #666666;
}
a.audit-item-article-flag-link i {
  font-size: 2em;
}
a.audit-item-article-flag-link span {
  display: none;
}
a.audit-item-article-flag-link.active {
  color: #428bca;
}

a.audit-item-article-hyperlink {
  cursor: pointer;
  color: #cccccc;
  display: block;
  margin: 15px 0 0 6px;
}
a.audit-item-article-hyperlink:hover {
  color: #428bca;
}
a.audit-item-article-hyperlink i {
  font-size: 1.2em;
}

.search-results-patient-snv > div > ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.search-results-patient-snv > div > ul > li {
  list-style: none;
  margin: 0 10px 10px 10px;
  padding: 0 0 10px 0;
  border-bottom: solid #ddd 1px;
}

.pull-right .dataTables_info {
  float: right;
}
ul.search-result-list {
  background-color: #ffffff;
  border: 0;
}

form#patient-snv-audit textarea {
  height: 100px;
}
form#patient-snv-pubmed-add-form button {
  margin-top: 10px;
}
form#patient-snv-audit-form button {
  margin-top-disabled: 10px;
}

.fileinput-button {
  position: relative;
  overflow: hidden;
}
.fileinput-button input {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  opacity: 0;
  -ms-filter: "alpha(opacity=0)";
  font-size: 200px;
  direction: ltr;
  cursor: pointer;
}

/* Fixes for IE < 8 */
@media screen\9 {
  .fileinput-button input {
    filter: alpha(opacity=0);
    font-size: 100%;
    height: 100%;
  }
}

#snv-comment-files,
#cnv-comment-files {
  padding: 3px 0;
  width: 100%;
}
#snv-comment-files p,
#cnv-comment-files p {
  background-color: #eeeeee;
  padding: 5px 5px 5px 10px;
  font-weight: bold;
  margin: 0;
}

/* Genoverse */
.gv-menu tr.header .glyphicon-zoom-in {
  margin-left: 7px;
  cursor: pointer;
}

/* Project gene panels */
ul.search-results-genes {
  list-style: none;
  margin: 0;
  padding: 0;
}
ul.search-results-genes li {
  margin: 0 0 5px 0;
  padding: 0;
  clear: both;
  width: 100%;
  height: 32px;
  border-bottom: solid #eeeeee 1px;
}
ul.search-results-genes li:hover {
  background-color: #eeeeee;
  border-bottom: solid #cccccc 1px;
}
ul.search-results-genes li span {
  float: left;
  display: block;
  padding: 6px 10px;
}
ul.search-results-genes li button {
  float: right;
  margin: 4px;
}
ul.search-results-genes li:hover button {
  background-color: #0088cc;
  color: #ffffff;
  border-color: #0088cc;
}

/* Help */
.display-help,
.display-help-manual {
  position: relative;
  float: right;
  top: 6px;
  right: 28px;
}

.modal-body .display-help .glyphicon-info-sign.has-help {
  left: -3px;
  top: 0;
}

.has-help {
  color: #0088cc !important;
  cursor: pointer;
  font-size: 1.2em;
  position: absolute;
  // It should have `z-index` smaller than modals (1050).
  z-index: 1049;
  left: 5px;
}
.has-help:hover {
  color: #357ebd !important;
}

/* datatables slightly overlap the help buton */
.display-help + .dataTables_wrapper {
  padding-top: 20px;
}

.tab-pane#genes_coverage .has-help {
  top: 52px;
}

/* Override introjs positioning on modal dialogs */
.modal-dialog.introjs-fixParent {
  position: relative !important;
}
.modal2.introjs-fixParent {
  z-index: 9999 !important;
}

.wrap-overflow {
  word-break: break-all;
}

/*Metrics*/
.metrics .nvd3 .nv-brushBackground rect {
  fill: #000 !important;
}

div.metrics svg {
  height: 500px;
}

#project-other-patients-snv-table tbody tr td:first-child {
  word-break: break-all;
}

.comparison_patient {
  white-space: nowrap;
}

.modal table td {
  word-break: break-word;
}

#login.form-horizontal .control-label {
  text-align: right;
}

.homepage-intro {
  text-align: center;
  margin-bottom: 50px;
}

.homepage-outro {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 30px;
}

.homepage-disclaimer {
  margin-top: 50px;
}

/* Tiering */
.penetrance button {
  padding: 2px 4px !important;
  margin: 0 0 3px 0 !important;
}

.penetrance button.complete {
  background-color: #00a2e8 !important;
  color: #fff !important;
  border-color: #00a2e8 !important;
  margin-left: -1px !important;
  opacity: 0.65;
}

.penetrance button.complete:hover {
  background-color: #00a2e8 !important;
  color: #fff !important;
  border-color: #00a2e8 !important;
}

.penetrance button.incomplete {
  background-color: #fff !important;
  color: #00a2e8 !important;
  border-color: #00a2e8 !important;
  margin-left: -1px !important;
  opacity: 0.65;
}

.penetrance button.incomplete:hover {
  background-color: #fff !important;
  color: #00a2e8 !important;
  border-color: #00a2e8 !important;
}

/* Decision status icons */
.icon_status_decision_approved {
  color: #5cb85c;
}
.icon_status_decision_rejected,
.icon_status_decision_pending {
  color: #d9534f;
}
.active .icon_status_decision_approved,
.active .icon_status_decision_pending {
  color: #ffffff;
}

table#patient-svs-table {
  width: 100%;
}

#patient-svs-table_processing {
  display: block;
  position: absolute;
  width: 367px;
  padding: 20px 0;
  text-align: center;
  font-size: 2em;
  color: #999999;
  z-index: 1000;
  background-color: white;
  border: solid #cccccc 1px;
  margin-left: 479px;
  margin-top: 107px;
}
#patient-svs-table_info {
  padding: 0 0 10px 0;
}

#sv-table-header {
  position: sticky;
  height: $table-header-height;
  top: 0;
  background: #ffffff;
  z-index: 10;
}

#sv-columns-manager {
  margin: 10px 0;
}

#patient-svs-table:not(.table-promoted) th {
  position: sticky;
  top: $table-header-height - 1px;
  z-index: 5;
}

table#patient-svs-table tbody tr {
  cursor: pointer;
}
table#patient-svs-table tbody tr.active td {
  background-color: #0088cc !important;
  color: white;
}

table#patient-svs-table tbody tr td .button-dropdown-group {
  color: #333;
}

.curated-variants-column.sv-table-cvl {
  flex-direction: column;
}

/* Genes */
table#patient-svs-table tbody .sv_row_genes {
  max-height: 200px;
  overflow-y: auto;
}

#sv-main-tabs {
  display: inline-block;
}

/* SV table/karyogram filters block */
#sv-form-filter-div {
  padding-top: 5px;
  display: flex;
}

#sv-form-filter-div .sv-form-icons {
  flex-shrink: 0;
}

#sv-form-filter-div .bootstrap-select .dropdown-toggle .filter-option {
  padding-right: 0;
  text-overflow: ellipsis;
}

#sv-form-filter-div .sv-reset-filters-icons {
  position: relative;
  margin-left: 10px;
  margin-right: 10px;
}

#sv-form-filter-div .sv-reset-filters-icons .glyphicon-remove {
  position: absolute;
  left: 9px;
  top: 6px;
  font-size: 10px;
}

#sv-form-filter-div .sv-reset-filters-icons {
  cursor: pointer;
}

.sv-reset-filters-icons.disabled {
  cursor: default;
  pointer-events: none;
}

#sv-form-filter-div .sv-reset-filters-icons.disabled .glyphicon {
  color: #777;
}

#sv-form-filter-div .sv-reset-filters-icons.disabled .glyphicon-remove {
  display: none;
}

#sv-form-filter-div div.btn-group.bootstrap-select {
  width: 165px;
}

/* align reset filter button */
#sv-form-filter-div .reset-filters-btn {
  float: right;
}

/* select2 gene panels field */
#sv-form-filter-div .sv-form-gene-panels-container {
  display: inline-block;
  vertical-align: top;
}

#sv-form-filter-div .select2-container {
  width: 335px;
}

#sv-form-filter-div .select2-choices {
  border-radius: 4px;
}

.select2-gene-panels
  .select2-results-dept-0:not(:first-of-type)
  > .select2-result-label {
  /* the styling part of a workaround for missing cross-page options merge functionality */
  padding: 0;
  min-height: 0;
}

.select2-gene-panels.panels-disabled .select2-results .select2-result-label {
  cursor: not-allowed;
}

.select2-gene-panels.panels-disabled .select2-result-sub,
.select2-gene-panels.any-gene-disabled
  .select2-results
  > .select2-result:first-child {
  color: #adadad;
}

.select2-gene-panels.any-gene-disabled
  .select2-results
  > .select2-result:first-child
  > .select2-result-label {
  cursor: not-allowed;
}

/* Centre Tabs */
#sv-main-tabs.nav-tabs > li,
#sv-main-tabs.nav-pills > li {
  float: none;
  display: inline-block;
  *display: inline; /* ie7 fix */
  zoom: 1; /* hasLayout ie7 trigger */
}

#sv-main-tabs.nav-tabs,
#sv-main-tabs.nav-pills {
  text-align: center;
}

/* karyogram loading */
#karyogram-container:not(.loaded)::before {
  content: "Loading... ";
}

#karyogram-container:not(.loaded) {
  text-align: center;
  font-size: 1.6em;
}

#karyogram-container:not(.loaded) .karyogram-container {
  opacity: 0.5;
  text-align: left;
}

/* project users table - roles */
.project-users-table__email,
.project-users-table__access,
.project-users-table__last-login,
.project-users-table__auth-provider,
.project-users-table__visibility,
.project-users-table__actions {
  width: 10rem;
}

.label-role {
  margin: 0 2px 2px 0;
}
.label-role-assigner {
  background-color: #428bca;
}
.label-role-approver {
  background-color: #5cb85c;
}
.label-role-authoriser {
  background-color: #f0ad4e;
}

/* karyogram itself */
.karyogram-container .gv-canvas-container {
  margin-bottom: 25px;
}
.karyogram-container .gv-canvas-container .gv-wrapper {
  overflow: visible;
  display: inline-block;
  margin-left: -5px;
  top: 2px;
}
.karyogram-container .gv-canvas-container .gv-label-container {
  width: 115px;
  display: inline-block;
}
.karyogram-container .gv-canvas-container.legend .gv-wrapper {
  top: 0;
}
.karyogram-container .gv-canvas-container .gv-label-container li {
  line-height: 12px;
}
.karyogram-container .gv-canvas-container .gv-label-container li .gv-handle {
  display: none;
}
.karyogram-container .gv-canvas-container .gv-label-container li .gv-name {
  width: 100%;
  top: 50%;
  left: 0 !important;
  margin: -5px 0 0;
}
.karyogram-container .gv-canvas-container.legend {
  padding-top: 5px;
  border-top: 1px solid black;
}
.karyogram-container .gv-canvas-container.legend.top {
  padding-top: 0;
  border-top: 0;
  border-bottom: 1px solid black;
}
.karyogram-container .gv-canvas-container.snv .gv-label-container {
  min-width: 115px;
}
.karyogram-container .gv-canvas-container.cnv .gv-label-container {
  min-width: 185px;
}
.karyogram-container .gv-canvas-container.cnv-histogram .gv-label-container {
  min-width: 180px;
}
.karyogram-container .gv-canvas-container .gv-wrapper {
  border: 0;
}
.karyogram-container .gv-canvas-container .gv-label-container {
  border: 0;
  background: none;
  box-shadow: none;
}
.karyogram-container .gv-canvas-container .gv-label-container li {
  box-shadow: none !important;
}
.karyogram-container .gv-canvas-container .gv-label-container li .gv-name {
  color: black;
  text-shadow: none;
}

#karyogram-container .histogram-message.alert-info {
  margin-bottom: 20px;
}

/* Gene Summary */
.gene-summary dt {
  float: left;
  width: 34px;
  overflow: hidden;
  clear: left;
  text-align: right;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.gene-summary dd {
  width: 100px;
  margin-left: 42px;
}

.export-data-buttons {
  padding-bottom: 10px;
}

.export-data-buttons h4 {
  padding-bottom: 5px;
}

.export-data-buttons .btn-default {
  background-color: #efefef;
  border-color: transparent;
}

/* Bootstrap overrides/extensions for IR submission page */
.table-cell-checkbox .checkbox input {
  float: none;
  margin-left: 0;
}
