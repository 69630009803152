@import "src/scss/_tools/variables/bootstrap-media-queries-vars";

.component {
  min-height: 75px;
  border: none;
  padding-left: 15px;
  background-color: #fff;

  &.withLimitedWidth {
    max-width: 1170px;
    margin: 0 auto;
  }

  a {
    cursor: pointer;
  }

  @media (max-width: $screen-xs-max) {
    li > a {
      display: inline-block;
    }
  }

  &LogoLink {
    &:active, &:focus, &:hover {
      outline: none;
      text-decoration: none;
    }

    @media (max-width: $screen-xs-max) {
      display: inline-flex !important;
      align-items: flex-start;
      flex-direction: column;
    }
  }

  &Logo {
    display: inline-block;
    max-height: 50px;
    width: auto;
  }

  &DevLabel {
    display: inline-block;
    margin-top: 6px;
    margin-left: 25px;
    font-size: 13.5px;
    text-transform: uppercase;
    vertical-align: top;

    @media (max-width: $screen-xs-max) {
      margin-left: 0;
    }
  }

  .navbarHeader {
    padding-top: 20px;
    padding-left: 8px;
    padding-bottom: 5px;
  }

  .navbarCollapse {
    border-top: none;
    padding-right: 0;
  }

  .navbarNav {
    padding-top: 5px;
    margin-right: 0 !important;

    & > li > a {
      padding: 10px 15px;
    }
  }

  .dropdown {
    // It's a way for JS to detect that page is in `xs` size mode. It's hacky, but I guess `window.matchMedia`
    // is not good enough too, cos it forces to duplicate a `$screen-xs-max` value in JS code
    // (see `closeDropdownOnOutsideClick` method of `Header.tsx`).
    @media (max-width: $screen-xs-max) {
      max-width: $screen-xs-max;
    }
  }
}
