.breadcrumbs {
  margin: $spacing-small 0;
  font-size: $font-size-body-medium;
  font-weight: 200;
  line-height: 1.4;

  &-crumb {
    display: inline;
  }

  &-divider {
    margin-left: 5px;
    margin-right: 5px;
  }
}
