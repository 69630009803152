.visible {
  visibility: visible;
}

.notVisible {
  visibility: hidden;
}

.container{
  position: relative;
  display: flex;
  flex-direction: column;
}

.warning{
  opacity: 0.85;
  position: absolute;
  top: -52px;
  left: 0;
  z-index: 10;
}

.info {
  align-self: flex-end;
}
