.upload {
  &-container {
    width: 600px;
    margin-top: 50px;
  }

  &-drop-folder-section {
    float: left;
    height: 200px;
    width: 320px;
    border: 2px solid rgb(101, 101, 101);
    border-radius: 20px;
    background-color: rgb(205, 205, 205);
  }

  &-drop-icon-container {
    position: absolute;
    height: 195px;
    width: 315px;
  }

  &-drop-icon-container-2 {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  &-icon {
    height: 40px;
    margin-bottom: 20px;
  }

  &-file-input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-appearance: none;
    display: block;
    overflow: hidden;
    opacity: 0;
  }

  &-btn {
    position: relative;
    overflow: hidden;
    cursor: pointer;
  }

  &-choose-folder-section {
    float: left;
    width: 250px;
    text-align: center;
    transform: translate(0, 50%);
  }

  &-drop {
    border: solid white 2px;

    &-active {
      border: dashed #ccc 2px;
    }
  }
}

.large-font {
  font-size: "large";
}

.margin-bottom-20 {
  margin-bottom: 20px;
}
