.modal {
  &.in {
    display: block;
  }

  &-container {
    position: absolute;

    &:before {
      background: $colour-transparent-black;
      bottom: 0;
      content: " ";
      display: none;
      left: 0;
      position: fixed;
      right: 0;
      top: 0;
      z-index: 1049; // @zindex-modal-1;
    }

    &.in:before {
      display: block;
    }
  }

  .modal-dialog {
    &.wide {
      width: $modal-wide-width;
    }
  }
}
