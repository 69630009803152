.date-label {
  @include bordered-element($colour-boston-blue);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(#{$font-size-body-medium} * 4);

  &-day {
    align-items: center;
    color: $colour-boston-blue;
    display: flex;
    flex: 1;
    font-size: calc(#{$font-size-body-medium} * 1.5);
    font-weight: bold;
    justify-content: center;
  }

  &-month {
    background-color: $colour-boston-blue;
    color: $colour-white;
    display: flex;
    font-size: calc(#{$font-size-body-medium} - 2px);
    justify-content: center;
  }
}
