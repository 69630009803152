.alert {
  display: flex;
  font-size: 100%;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 5px;
  border-style: solid;
  border-width: 1px;
}

.top {
  align-items: start;
}

.center {
  align-items: center;
}

.bottom {
  align-items: end;
}

.small {
  font-size: 90%;
  padding: 10px;
}

.medium {
  font-size: 100%;
  padding: 15px;
}

.large {
  font-size: 110%;
  padding: 20px;
}

.primary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}

.success {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #c3e6cb;
}

.error {
  color: #b94a48;
  background-color: #f2dede;
  border-color: #f5c6cb;
}

.warning {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #ffeeba;
}

.info {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bee5eb;
}

.actions {
  margin-left: auto;
}

.icon {
  margin: 1.5px 15px 0 0;
}

.content {
  display: block;
}
