.filter-sidebar {
  > div {
    padding-bottom: $spacing-medium;
  }

  ul.filter-section-value {
    margin: 0;
    padding: 0;

    li:not(.list-item) {
      margin: 0;
      padding: 0 0 0.4em;
      list-style: none;
    }

    @media (max-width: $screen-sm-max) {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;

      li {
        min-width: 50%;
        max-width: 50%;
      }
    }
  }

  .glyphicon-ok {
    padding-right: 5px;
    color: #999;
    font-size: 0.8em;
  }

  &-title {
    padding: 8px 0.5em;
    text-align: left;
    font-weight: 700;
    border-top: 1px solid #ddd;
    border-bottom: 2px solid #ddd;
    margin-bottom: 0.7em;

    &-text {
      display: inline-block;
      margin: 0;
      line-height: 1.4em;
    }

    &-icons {
      float: right;

      .glyphicon {
        margin-left: 5px;
        color: $colour-silver;
        cursor: pointer;
        float: left;
        transition: 0.1s ease-in;
        padding-right: 0;

        &:hover:not(.disabled) {
          color: #08c;
        }

        &.disabled {
          cursor: not-allowed;
        }
      }
    }
  }

  .title {
    margin-right: 5px;
  }
}

.filter-section {
  margin-bottom: -$spacing-small;

  &-header {
    margin: 0 0 0.7em 0;
    font-weight: 700;
    color: #428bca;
    margin-bottom: 0.4em;
    font-size: 14px;
  }

  &-wrapper {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .nested {
    margin-bottom: 0;
    .filter-section-header {
      color: $colour-mine-shaft;
      font-weight: normal;
      display: inline-block;
    }
  }
}
