.search-bar {
  .search-result-item {
    align-items: center;
    border-bottom: 1px solid $colour-black;
    cursor: pointer;
    display: flex;
    padding: 4px 8px;

    .icon {
      margin: 0 $base-spacing 0 calc(#{$base-spacing} / 2);
    }
  }
}

.search-result-text {
  flex: 1;
  padding: 4px 0;
  user-select: none;

  .pre {
    margin-right: 6px;
  }

  .main-title {
    margin-bottom: 3px;
  }

  .sub-title {
    font-size: 12px;
  }
}
