.sequence-runs-modal {
  &-container {
    display: flex;
  }

  &-icon {
    font-size: $font-size-body-medium * 2;
    margin-right: $font-size-body-medium;
    width: $font-size-body-medium * 2;
  }
}
