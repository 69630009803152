@import "src/scss/_tools/variables/bootstrap-media-queries-vars";

.footer {
  width: 100%;
  line-height: 20px;
  margin-top: 25px;
  border-top: 1px solid #dddddd;
  padding-top: 9px;
  padding-bottom: 10px;
  background-color: #f5f5f5;
  font-size: 0.85em;

  &WidthLimiter {
    max-width: 1140px;
    margin: 0 auto;
  }

  ul li {
    list-style-type: none;
  }

  [class~="glyphicon"] {
    font-size: 10px;
  }

  &Section {
    max-width: 50%;

    &:nth-child(1) {
      float: left;
    }
    &:nth-child(2) {
      float: right;
    }
  }

  &Link {
    cursor: pointer;
    white-space: nowrap;
  }

  @media (max-width: $screen-xs-max) {
    &Section {
      max-width: none;
      margin-bottom: 10px;

      &:nth-child(n) {
        float: none;
      }

      &:last-child {
        margin-bottom: 0;
      }

      ul {
        padding-left: 0;
      }
    }
  }
}
