// Default variables for genes
// $gene-{name}

$gene-width: 15em;

$gene-button-background-color: $colour-boston-blue;
$gene-button-hover-background-color: $colour-cornflower-blue;

$gene-morbid-background-color: $colour-vermilion;

$gene-active-background-color: darken($colour-selago, 2);
