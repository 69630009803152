.rules {
  border: {
    left: {
      color: $base-border-colour;
      style: solid;
      width: $base-border-width;
    }
  }

  list-style: none;
  margin: $spacing-small 0;
  padding: 0;
}
