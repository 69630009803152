// Default variables for variants
// $variant-{name}

// variant row
$variant-row-border-color: $colour-loblolly;

$variant-row-hover-background-color: $colour-selago;

// variant column
$variant-column-min-width: 130px;

// Different columns need to be wider or narrower - these are stored here
$variant-column-custom-columns: (
  variant: 130px,
  transcript: 210px,
  vepConsequence: 15em,
  hgvsC: 18em,
  hgvsP : 18em,
  polyphenScore: 10em,
  siftScore: 5em,
  ariadneComputedDecision: 140px
);
