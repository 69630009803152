.draggable-checkbox-list {
  .item {
    cursor: pointer;
    display: block;
    list-style-type: none;
    min-height: $draggable-checkbox-list-item-min-height;

    &-input {
      cursor: pointer;
      margin-right: $draggable-checkbox-list-item-input-margin-right;
      position: relative;
    }

    &-label {
      cursor: pointer;
      font-weight: inherit;
    }

    &-arrow {
      color: $draggable-checkbox-list-item-arrow-color;
      font-size: $draggable-checkbox-list-item-arrow-font-size;
      padding-right: $draggable-checkbox-list-item-arrow-padding-right;
      top: $draggable-checkbox-list-item-arrow-top;
    }

    &-movable {
      cursor: $draggable-checkbox-list-item-movable-cursor;
      list-style-type: none;
    }

    &-top-ul {
      margin-bottom: 0;
      padding-left: 0;

      ul {
        padding-left: $spacing-medium;
      }
    }

    &-top-ul:not([draggable='true']) {
      li {
        cursor: not-allowed !important;
        padding-left: 12px;
        label, input{
          cursor: not-allowed !important;
        }
      }
    }
  }

  .is-dragging {
    opacity: 0.2;
  }
}

// this is the legacy code - can we remove it?
.list-item {
  display: block;
  cursor: pointer;
  list-style-type: none;
  min-height: 24px;

  input[type="checkbox"] {
    cursor: pointer;
    margin-right: 0.5em;
    position: relative;
    top: 2px;
  }

  label {
    font-weight: inherit;
  }
}

.top-ul {
  padding-left: 0;

  ul {
    padding-left: 2em;
  }
}

.movable-list-item {
  cursor: -webkit-grab!important;
  cursor: grab !important;
  list-style-type: none;
}

.arrow {
  top: 1px;
  padding-right: 0.3em;
  font-size: 0.7em;
  color: #ccc;
}

fieldset[disabled] {
  .draggable-checkbox-list {
    cursor: not-allowed;
    .item-top-ul {
      pointer-events: none;
    }
    .item-arrow {
      visibility: hidden;
    }
  }
}
