@use "sass:math";

.group {
  align-self: flex-start;
  border: solid $base-border-colour $base-border-width;
  display: inline-block;
  margin: $group-margin;
  min-width: $group-min-width;
  position: relative;

  &-title {
    background-color: $group-title-background-colour;
    font-size: $group-title-font-size;
    font-weight: normal;
    left: $base-inline-spacing;
    margin: 0;
    position: absolute;

    &-bottom {
      bottom: -(($group-title-font-size * $base-line-height) * 0.5);

      &:last-child {
        margin-left: 0;
      }
    }

    &-top {
      top: -(($group-title-font-size * $base-line-height) * 0.5);

      &:first-child {
        margin-left: 0;
      }
    }
  }

  &-content {
    align-items: center;
    display: flex;
    font-size: $group-content-font-size;
    justify-content: center;
    margin: $base-inline-spacing $base-spacing;
    text-align: center;
  }

  &--compact {
    .group-content {
      font-size: math.div($group-content-font-size, 3);
    }
  }

  &-round-border {
    align-items: center;
    align-self: flex-start;
    color: $group-round-border-color;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: $group-round-border-margin;
    max-width: $group-round-border-size;
    min-width: $group-round-border-size;

    &-title {
      background-color: $group-round-border-title-background-colour;
      font-size: $group-round-border-title-font-size;
      font-weight: normal;
      line-height: $group-round-border-title-line-height;
      margin: $base-inline-spacing $base-spacing;
      text-align: center;
      word-wrap: normal;
    }

    &-content {
      align-items: center;
      background-color: $group-round-border-content-background-color;
      border: solid $base-border-colour $base-border-width;
      border-radius: 50%;
      display: flex;
      font-size: $font-size-body-large;
      height: $group-round-border-content-size;
      justify-content: center;
      text-align: center;
      width: $group-round-border-content-size;
    }
  }
}
