.variant-pathogenicity-badge {
  display: inline-block;
  border-radius: 10px;
  padding: 10px;
  font-size: 12px;

  &--size-small {
    padding: 1px 7px;
  }

  &--pathogenic {
    border: 1px solid red;
    color: darken(red, 20%);
    background-color: adjust-color(red, $alpha: -0.95);
  }

  &--likely_pathogenic {
    border: 1px solid #ff7400;
    color: darken(#ff7400, 20%);
    background-color: adjust-color(#ff7400, $alpha: -0.95);
  }

  &--likely_benign, &--benign {
    border: 1px solid #428bca;
    color: darken(#428bca, 20%);
    background-color: adjust-color(#428bca, $alpha: -0.95);
  }
}
