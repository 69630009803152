// sass-lint:disable no-vendor-prefixes no-color-literals no-transition-all
.toggle {
  -webkit-appearance: none;
  appearance: none;
  background-color: $toggle-background-colour;
  border: 1px solid $colour-grey-400;
  border-radius: $spacing-border-radius-medium;
  cursor: pointer;
  display: inline-block;
  height: $font-size-body-medium * 2;
  margin-right: $base-inline-spacing;
  min-width: $toggle-width;
  outline: none;
  overflow: hidden;
  position: relative;
  width: $toggle-width;

  &[disabled] {
    opacity: 0.6;
  }

  &[disabled]:checked {
    opacity: 0.6;
    background-color: #428bca;
  }

  &-label {
    align-items: center;
    display: flex;
  }

  &::before {
    background: $toggle-active-colour;

    border-radius: $spacing-border-radius-medium 0 0
      $spacing-border-radius-medium;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);

    content: "Yes No";
    display: block;
    font: $toggle-before-font;
    height: $font-size-body-medium * 2;
    position: absolute;
    text-indent: $toggle-before-text-indent;
    white-space: nowrap;
    width: $toggle-width * 0.5;
    word-spacing: $toggle-before-word-spacing;
    z-index: 2;
  }

  &:checked {
    background-color: $colour-lochmara;
    color: $toggle-active-colour;

    &::before {
      border-radius: 0 $spacing-border-radius-medium
        $spacing-border-radius-medium 0;
      left: $toggle-width * 0.5;
    }
  }
}

// Bootstrap override
.checkbox input[type="checkbox"].toggle {
  margin: 0 $base-inline-spacing 0 0;
  outline: none;
  position: relative;
}

.checkbox.toggle-container label {
  align-items: center;
  display: flex;
  padding-left: 0;
}

.row > .toggle-container {
  margin-bottom: 0;
  margin-top: 0;
}

.checkbox > .bold {
  font-weight: 700;
}

.form-horizontal .toggle-container {
  padding: 0;
  width: auto;
  display: inline-block;
}
