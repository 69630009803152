@use "sass:math";

#sv-container {
  #sv-filter-sidebar.filter-collapsed + .sv-content {
    max-width: calc(100% - 49px); // .structural-variants width + h-padding

    @media (max-width: $screen-sm-max) {
      max-width: 100%;
    }
  }

  #sv-filter-sidebar:not(.filter-collapsed) + .sv-content {
    max-width: calc(100% - 328px); // .structural-variants min-width + h-padding + h-border + h-margin

    @media (max-width: $screen-sm-max) {
      max-width: 100%;
    }
  }

  @media (max-width: $screen-sm-max) {
    .flex {
      flex-wrap: wrap;

      & > * {
        flex-grow: 1;
        max-width: 100%;
      }
    }
  }
}

.structural-variants {
  position: sticky;
  top: 0;
  min-width: 310px;
  max-width: 310px;
  max-height: 100vh;
  padding: $spacing-small;
  margin: $spacing-small;
  overflow: auto;
  overflow-x: hidden;
  background: #ffffff;
  border: solid $colour-grey-400;
  border-width: 0 1px 0 0;
  z-index: 15;

  &-collapsed {
    position: sticky;
    top: 0;
    display: grid;
    grid-auto-columns: 40px;
    gap: $spacing-small*0.5;
    padding: $spacing-small $spacing-small 0 0;
    z-index: 15;
    .structural-variants-filter {
      display: none;
    }
  }

  @media (max-width: $screen-sm-max) {
    &, &-collapsed {
      position: static;
      min-width: auto;
      max-width: none;
      max-height: none;
      margin: 0 0 $spacing-medium;
      border-width: 0 0 1px;
      padding: 0;
    }

    &-collapsed {
      grid-template-columns: repeat(7, 40px); // We have 3 buttons, 7 is small reserve.
    }

    &-filter-row {
      display: flex;
      flex-wrap: wrap;

      & > * {
        float: none;
      }
    }
  }

  @media (max-width: $screen-xs-max) {
    &-filter-row {
      display: block;
    }
  }

  &-btn {
    &-collapse {
      float: right;
      padding-bottom: math.div($spacing-small, 3);

      @media (max-width: $screen-sm-max) {
        float: none;
      }
    }
  }

  &-preset {
    &-footer {
      display: grid;
      grid-auto-flow: column;
      gap: $spacing-small;
      padding: $spacing-small 0;
    }
  }

  &-patient-overlap-table {
    overflow-y: auto;
    &-tags-column {
      min-width: 320px !important;
      display: inline-block;
      line-break: anywhere !important;
    }
  }

  &-table {
    &-column {
      &-hi-gene-names {
        display: block;
        > button {
          margin: 2px;
        }
        > div {
          white-space: nowrap;
          display: inline-block;
          margin: 2px;
        }
      }
    }
  }

  .structural-variants-filter {
    .radio,
    .checkbox-group {
      margin-top: 0;
      margin-bottom: 0;
    }
    .form-group {
      margin-bottom: $spacing-small;
    }
  }
}

.structural-variants-details {
  @media (max-width: 1620px) {
    margin-bottom: $spacing-medium;
  }

  .variant-tabs-heading {
    &-col {
      // It has before `col-md-8` ...
      width: 66.66666667%;
      padding-right: 15px;
      padding-left: 15px;

      // ... but it should be 100% after:
      @media (max-width: 1620px) {
        width: 100%;
      }
    }
  }

  .variant-tabs-details-col {
    float: left;
    position: relative;
    width: 33.33333333%;
    padding-left: 15px;
    padding-right: 15px;

    @media (max-width: 1620px) {
      width: 50%;

      &_decision {
        width: 100%;
      }
    }
  }
}
