.tip {
  background-color: $help-background-colour;
  box-sizing: border-box;
  color: $help-foreground-colour;
  cursor: default;
  display: none;
  // Hide the tip offscreen until it has rendered
  left: -1000000px;
  padding: $base-spacing;
  position: absolute;
  text-align: center;
  z-index: $help-z-index;

  &.is-visible {
    display: block;
  }

  &-close {
    cursor: pointer;
    position: absolute;
    right: $base-inline-spacing;
    top: $base-inline-spacing;
  }
}
