@import "_tools/index";
// Legacy Sapientia styles
@import "legacy/homepage";
@import "legacy/patient-audit";
@import "legacy/patient-comments";
@import "legacy/sapientia-structure";
@import "legacy/sapientia-theme";
@import "legacy/variant-panel";
@import "legacy/variants-table";

@import "legacy-overwrites";

@import "~bootstrap-select/dist/css/bootstrap-select.min.css";
@import "~tippy.js/dist/tippy.css";
@import "~nvd3/build/nv.d3.min.css";
@import "~genoverse/build/genoverse.css";


// @import "base/**/*";
@import "base/input";
@import "base/input-group";
@import "base/form-group";
@import "base/table";
@import "base/common";

// @import "layout/**/*";
@import "layout/app";
@import "layout/container";
@import "layout/tabs";
@import "layout/header";
@import "layout/patient-layout";

// @import "modules/**/*";
@import "modules/checkboxes";
@import "modules/checkbox-group";
@import "modules/draggable-checkboxes";
@import "modules/filter-sidebar";
@import "modules/transcript";
@import "modules/datepicker";
@import "modules/hpo-terms";
@import "modules/tree-node";
@import "modules/acmg";
@import "modules/message";
@import "modules/btn";
@import "modules/upload";
@import "modules/buttons";
@import "modules/button-dropdown";
@import "modules/carousel";
@import "modules/group";
@import "modules/icons";
@import "modules/modal";
@import "modules/progress";
@import "modules/promo";
@import "modules/recaptcha";
@import "modules/rules";
@import "modules/search";
@import "modules/sequence-run";
@import "modules/sequence-runs";
@import "modules/toggle";
@import "modules/users";
@import "modules/settings-form";
@import "modules/upload-progress";
@import "modules/status-progress";
@import "modules/sequence-runs-uploading-table";
@import "modules/sequence-runs-modal";
@import "modules/info-field";
@import "modules/run-qc-overview-modal";
@import "modules/accordion";
@import "modules/search-bar";
@import "modules/breadcrumbs";
@import "modules/tooltip";
@import "modules/graph";
@import "modules/credits-table";
@import "modules/credits-controls";
@import "modules/request-credits-modal";
@import "modules/article";
@import "modules/sortable-wells";
@import "modules/decision";
@import "modules/timeline";
@import "modules/date-label";
@import "modules/file-thumbnail";
@import "modules/pane";
@import "modules/form-field";
@import "modules/well";
@import "modules/slidedown";
@import "modules/tag";
@import "modules/roles";
@import "modules/auth";
@import "modules/systemConfig";
@import "modules/help/tip";
@import "modules/help/tutorial-control";
@import "modules/curated-variant-modal";
@import "modules/info-list";
@import "modules/divider";
@import "modules/loading";
@import "modules/loading-overlay";
@import "modules/label";
@import "modules/interpretation-requests";
@import "modules/radiobuttons";
@import "modules/ref-automation";
@import "modules/ref-no-call";
@import "modules/projects-table";

@import "modules/sequence_variants_presets";
@import "modules/structural-variants-presets";
@import "modules/curated-variants-lists";
@import "modules/variant-tables/variant";
@import "modules/variant-tables/gene";
@import "modules/variant-tables/hi-score";
@import "modules/variant-tables/morbid";
@import "modules/variant-tables/tiers";
@import "modules/variant-tables/curated-variants";
@import "modules/variant-tables/config";
@import "modules/check-list";
@import "modules/actions";
@import "modules/select";
@import "modules/exomiser";
@import "modules/list";
@import "modules/filter-presets";
@import "modules/patients";
@import "modules/configuration/filters";
@import "modules/audit";
@import "modules/project-users";
@import "modules/navbar";
@import "modules/str/str-panel";
@import "modules/projectInterpretationRequests";
@import "modules/report-templates";
@import "modules/patient_metadata";
@import "modules/project-settings";
@import "modules/project-gene-panels";
@import "modules/panogram";
@import "modules/structural-variants";
@import "modules/qc";
@import "modules/oncology_report";
@import "modules/about-modal";
@import "modules/sequence-variants-summary";
@import "modules/automation";
