// We have Bootstrap 3 based on less in our `node_modules`,
// here is a `sass` version of bootstrap media-queries variables.
// https://getbootstrap.com/docs/3.3/css/#grid
// node_modules/bootstrap/less/variables.less:283

$screen-xs-min:              480px;
$screen-sm-min:              768px;
$screen-md-min:              992px;
$screen-lg-min:              1200px;

$screen-xs-max:              ($screen-sm-min - 1);
$screen-sm-max:              ($screen-md-min - 1);
$screen-md-max:              ($screen-lg-min - 1);
