@use "sass:math";

@import "src/scss/_tools/variables/bootstrap-media-queries-vars";

.table {
  border: solid $base-border-colour $base-border-width;
  border-collapse: collapse;
  width: 100%;

  &-container {
    position: relative;

    .custom-filter {
      display: flex;
      justify-content: space-around;
      margin: 0 $spacing-medium;

      label {
        white-space: nowrap;
        margin: $spacing-inline-medium;
      }

      @media (max-width: $screen-sm-max) {
        margin-left: 0;
        margin-right: 0;
      }
    }

    .title-help-text {
      margin-bottom: $spacing-x-small;
    }
  }

  &-no-data {
    background: $table-no-data-background-colour;
    color: $table-no-data-colour;
    display: block;
    left: 50%;
    padding: 20px;
    pointer-events: none;
    position: relative;
    width: fit-content;
    transition: 0.3s ease 0s;
    z-index: 1;
    margin-bottom: 20px;
    transform: translateX(-50%);
  }

  &-th {
    outline: none;
    padding: 6px;
    position: relative;
  }

  &-th.-sort-asc,
  &-th.-sort-desc {
    padding-right: $spacing-large;
  }

  &-th.-sort-asc:before {
    border-bottom: 5px solid $table-sort-border-colour;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    content: "";
    height: 0;
    position: absolute;
    right: 10px;
    width: 0;
  }

  &-th.-sort-desc:before {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid $table-sort-border-colour;
    content: "";
    height: 0;
    position: absolute;
    right: 10px;
    width: 0;
  }

  &-footer {
    align-items: center;
    display: flex;

    &__page-size-options {
      float: right;
    }

    &-select {
      width: auto;
    }

    @media (max-width: $screen-xs-max) {
      flex-wrap: wrap;

      & > .col-xs-12 {
        display: flex;
        justify-content: center;
      }

      &__page-size-options {
        float: none;
      }
    }
  }

  &-header {
    align-items: baseline;
    display: flex;
    margin-bottom: $spacing-medium;

    &-info {
      align-items: baseline;
      display: flex;
      flex: 1;
      margin-bottom: 0;
      margin-top: 0;
    }

    &-filter {
      max-width: math.div($spacing-small-width, 3);
    }

    @media (max-width: $screen-xs-max) {
      flex-direction: column;

      &-info {
        margin-bottom: $spacing-small;
      }

      &-filter {
        max-width: 240px;
      }
    }
  }

  .table-hierarchical-row-node-path {
    display: none;
    font-style: italic;
  }

  &-filtered {
    .table-hierarchical-row {
      &-node-space-indent:before {
        margin-left: 0;
        transition: margin-left $animation-duration-slow;
      }

      &-node-icon {
        transform: rotateY(90deg);
        transition: transform $animation-duration-slow,
        width $animation-duration-slow $animation-duration-slow * 0.5;
        width: 0;
      }

      &-node-name {
        margin-left: 0;
        transition: margin-left $animation-duration-slow;
      }
    }

    .table-hierarchical-row-node-path {
      display: block;
    }
  }

  &-hierarchical-row {
    &-selected {
      color: $colour-white;

      .table-hierarchical-row-node-icon {
        color: $colour-white;
      }

      td {
        background-color: $colour-boston-blue;
      }
    }

    &-node {
      &-icon {
        color: $colour-boston-blue;
        transition: transform $animation-duration-slow $animation-duration-slow *
            0.25,
        width $animation-duration-slow;
        width: 14px;
      }

      &-name {
        margin-left: $spacing-small;
        font-weight: bold;
        transition: margin-left $animation-duration-slow;
      }

      &-space-indent:before {
        content: "";
        margin-left: $spacing-medium;
        transition: margin-left $animation-duration-slow;
      }
    }
  }
}

.table-loading {
  background: $table-loading-background;
  bottom: 0;
  display: block;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.3s ease;
  z-index: -1;

  .loading {
    color: $table-loading-div-color;
    display: block;
    font-size: 15px;
    left: 0;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translateY(-52%);
    transition: 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    width: 100%;
  }
}

.table-loading.table-active {
  opacity: 1;
  pointer-events: all;
  z-index: 2;

  .loading {
    transform: translateY(50%);
  }
}

.pagination-bottom {
  .pagination {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 15px;

    & > li {
      margin-bottom: 5px;
    }

    @media (max-width: $screen-xs-max) {
      justify-content: flex-start;
      margin: 0 0 8px;
    }
  }
}
