.app-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.app-content {
  flex-grow: 1;
  width: 100%;
}
