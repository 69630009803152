.about-modal-content {
  ul.info-list {
    margin-bottom: 0;
  }
  .info-field {
    margin-bottom: $spacing-small;
    align-items: flex-start;
    .name {
      white-space: pre-wrap;
      margin-top: 3px;
    }
  }
}
