.variant {
  &-filtered-out {
    color: $colour-dusty-gray;
    font-size: 14px;
    cursor: pointer;
    padding-top: $spacing-small;

    .chevron {
      margin: $spacing-inline-small;
    }
  }

  &-container {
    flex-grow: 1;
  }

  &-row {
    display: flex;
    padding: $base-inline-spacing 0;
    cursor: pointer;
    border-top: $base-border-width $base-border-style $variant-row-border-color;

    &-container {
      width: 100%;
    }

    &:first-child {
      border-top: 0;
    }

    &--is-active {
      background: $gene-active-background-color;
      cursor: default;
    }

    &--filtered-out {
      // $colour-mine-shaft is used as a Bootstrap default in the variants table
      color: $colour-transparent-mine-shaft;
    }

    &:hover {
      background-color: $variant-row-hover-background-color;
    }

    .variant {
      flex-direction: column;
    }
  }

  &-column {
    padding: 0 $spacing-small;
    max-width: $variant-column-min-width;
    width: $variant-column-min-width;
    min-width: $variant-column-min-width;
    box-sizing: border-box;

    display: flex;
    align-items: flex-start;
    word-break: break-word;

    &-label {
      word-break: break-word;
      white-space: normal;
    }

    &.variant {
      flex-wrap: wrap;
    }

    &.tier {
      display: block;
    }

    &.curated_variants_list {
      .curated-variants-item {
        color: $colour-mine-shaft;
      }
    }

    &-consequences {
      &-row {
        display: flex;

        &--bold {
          font-weight: bold;
        }
      }

      &-footer {
        display: inline;
        cursor: pointer;
        font-size: $font-size-body-small * 1.2;
        color: $colour-dusty-gray;

        .glyphicon {
          margin-right: $spacing-inline-small;
          font-size: $font-size-body-small;
        }
      }
    }

    @each $name, $width in $variant-column-custom-columns {
      &--#{$name} {
        width: $width;
        min-width: $width;
        max-width: $width;
      }
    }
    .variant-pathogenicity-badge {
      margin-bottom: $spacing-x-small;
    }
  }

  &-table {
    &-expand {
      position: absolute;
      left: 0;
      background-color: $colour-lochmara;
      color: $colour-white;
      padding: $spacing-inline-large $spacing-inline-small;
      z-index: 99;
      border-top-right-radius: $spacing-inline-small;
      border-bottom-right-radius: $spacing-inline-small;
      cursor: pointer;
      margin-top: 0;
      font-size: $font-size-body-small;
      transition: 0.2s ease-in-out;

      &:hover {
        padding-left: $spacing-inline-large;
      }
    }

    &-container {
      position: relative;

      .sticky {
        position: sticky;
        top: 0;
        height: 100vh;
        overflow: auto;
        z-index: 10;

        @media (max-width: $screen-md-max) {
          position: static;
          height: auto;
          overflow: visible;
        }
      }
    }

    &-overlay {
      @include block-overlay;
    }
  }
}

.variant-row > div:last-child .variant-column:last-child,
.variant-row > .variant-column:last-child {
  flex-grow: 1;
  max-width: unset;
  width: unset;
}
