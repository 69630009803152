.filter-presets-container {
  .toggle-container {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    label {
      padding-left: 0;
    }
  }
}

.filter-presets-inheritance {
  margin-bottom: $spacing-medium;
  span {
    padding-top: $spacing-small;
  }
  @media (max-width: $screen-xs-max) {
    span {
      display: block;
      padding-top: 0;
      padding-bottom: $spacing-small;
    }
    .toggle-container {
      display: flex;
      justify-content: flex-end;
    }
  }
}
.filter-presets-list {

  .variant-type-filter {
    span {
      padding-top: $spacing-small;
    }
  }
  .preset-form {
    max-width: $container-compact-max-width;

    @media (max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
      margin-top: $spacing-medium;
    }

    .inherited-preset-warning:not(:empty) {
      @media (max-width: $screen-md-max) {
        margin-bottom: $spacing-large;
      }
    }

    .actions--sticky {
      width: 0;
      float: right;
    }
  }
}

.presets-info-btn {
  padding-top: 8px;
  padding-bottom: 8px;
}
