.failureMessage {
  text-align: center;

  button {
    margin-top: 10px;
  }
}

.componentHeader {
  margin-bottom: 15px;
}

.componentTable {
  margin: 0 0 6px;
}
