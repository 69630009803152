// Default variables for Pathogenicities
// $pathogenicity-{property name}-{name}-{?state}

$pathogenicity-background-colour-pathogenic: $colour-red;
$pathogenicity-background-colour-pathogenic-hover: $colour-monza;
$pathogenicity-background-colour-likely-pathogenic: $colour-flush-orange;
$pathogenicity-background-colour-likely-pathogenic-hover: $colour-chilean-fire;
$pathogenicity-background-colour-uncertain-significance: $colour-koromiko;
$pathogenicity-background-colour-likely-benign: $colour-malibu;
$pathogenicity-background-colour-benign: $colour-malibu;
$pathogenicity-background-colour-excluded: $colour-silver-chalice;
$pathogenicity-background-colour-unknown: $colour-silver-chalice;

$all-pathogenicities: (
  pathogenic: $pathogenicity-background-colour-pathogenic,
  "likely-pathogenic": $pathogenicity-background-colour-likely-pathogenic,
  "uncertain-significance": $pathogenicity-background-colour-uncertain-significance,
  "likely-benign": $pathogenicity-background-colour-likely-benign,
  benign: $pathogenicity-background-colour-benign,
  excluded: $pathogenicity-background-colour-excluded,
  unknown: $pathogenicity-background-colour-unknown
)
