// Font properties
// $font-{property}-{type}-{size?}
// Size being small, regular, medium and large

// body sizes
$font-size-body-large: 50px;
$font-size-body-medium: 16px;
$font-size-body-regular: floor($font-size-body-medium * 0.9);
$font-size-body-standard: 12px;
$font-size-body-small: 10px;

// Fonts
$font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
  "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
  "Segoe UI Symbol" !default;
