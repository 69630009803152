.well {
  &-trio {
    margin-top: 3px;
    max-width: 33%;
    min-height: 216px;

    &:first-of-type {
      margin-right: 0.5%;
    }

    &:last-of-type {
      margin-left: 0.5%;
    }
  }
}
