.component {
  position: relative;

  // A visual effect that helps to understand that the block can be scrolled in a respective direction.
  &::before, &::after {
    content: "";
    display: block;
    position: absolute;
    z-index: 100;
    pointer-events: none;
    top: 0;
    width: 8px;
    height: 100%;
    background: #fcc;
    opacity: 0;
    transition: opacity .33s;
  }

  &::before {
    left: 0;
    background: linear-gradient(to right, rgba(#000, .1), rgba(#000, 0));
  }

  &::after {
    right: 0;
    background: linear-gradient(to left, rgba(#000, .1), rgba(#000, 0));
  }

  &.scrollableToLeft {
    &:before {
      opacity: 1;
    }
  }

  &.scrollableToRight {
    &:after {
      opacity: 1;
    }
  }
}
