// Default variables for the promo module
// $article-{subclass?}-{element?}-{state?}-{css property}

$article-flag-font-size: ($font-size-body-medium * 2);
$article-flag-width: ($font-size-body-medium * 2);
$article-flag-color: $colour-grey-400;
$article-flag-bookmarked-color: $colour-azure-radiance;

$article-hover-background-color: $colour-azure-radiance;
$article-hover-color: $colour-azure-radiance;

$article-line-min-width: 3px;
$article-normal-line-background-color: $base-border-colour;
$article-normal-line-margin: $spacing-small 0 $spacing-small 0;
$article-stripped-down-line-background-color: $colour-azure-radiance;
$article-stripped-down-line-margin: 0 $spacing-small 0 $spacing-small;
$article-reference-line-background-color: $base-border-colour;

$article-content-padding: $spacing-small;

$article-title-color: $colour-azure-radiance;
$article-stripped-down-meta-info-flag-margin-right: $spacing-small;

$article-property-label-margin-right: $spacing-small;

$article-normal-meta-info-min-width: ($spacing-small * 24);
$article-normal-meta-info-bookmark-min-height: ($spacing-small * 10);
$article-normal-meta-info-row-action-label-color: $colour-grey-700;
$article-normal-meta-info-row-action-label-margin: $font-size-body-medium 0 0
  ($spacing-small * 5);
$article-normal-meta-info-row-action-label-bookmarked-color: $colour-grey-500;

$article-reference-meta-info-min-width: ($spacing-small * 5);
$article-reference-meta-info-flag-left: $spacing-small;
$article-reference-meta-info-link-margin: $spacing-small 0 0 0;
$article-reference-meta-info-link-color: $colour-grey-400;

$article-transition: all 0.5s ease-out;
