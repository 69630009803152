//.auth-input-container{
//  padding-top: 15px;
//
//  .btn-group {
//    display: block;
//  }
//}

.api-token-modal {
  .modal-body {
    margin-bottom: 15px;

    &.status-success {
      padding-left: 30px;
      padding-right: 30px;
    }

    &.status-failure {
      color: $colour-congenica-red;

      div + button {
        margin-top: 15px;
      }
    }
  }
}
