.input-clear-btn {
  position: absolute;
  pointer-events: all;
  cursor: pointer;
}

input[type='file'].form-control {
  border: none;
  box-shadow: none;
  padding-left: 0;
}
