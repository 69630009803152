.wells-list {
  font-size: $sortable-well-font-size;
  margin-top: $spacing-medium;
  margin-bottom: $spacing-medium;

  & > .col-md-4 {
    @media (max-width: $screen-sm-max) {
      margin-bottom: $spacing-medium;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .sortable-well {
    &-group {
      &-header {
        font-size: $font-size-body-medium;
        margin-bottom: $spacing-small;
        text-align: center;
      }
    }

    &-header {
      font-size: $sortable-well-header-font-size;
      margin-bottom: $sortable-well-header-margin-bottom;
      padding-left: $sortable-well-header-padding-left;
    }

    &-container {
      background-color: $sortable-well-container-background-color;
      border: $sortable-well-border-width solid $sortable-well-border-colour;
      border-radius: $sortable-well-border-radius;
      min-height: $sortable-well-font-size + $spacing-small * 4;
      padding: $sortable-well-container-padding;

      &-active {
        border: $sortable-well-border-width dashed
          $sortable-well-container-active-border-colour;
      }

      &-not-allowed {
        opacity: 0.3;
      }

      &-hide-preview {
        .sortable-well-item-dragging {
          display: none;
        }
      }
    }

    &-item {
      background-color: $sortable-well-item-background-color;
      border: 1px solid $sortable-well-border-colour;
      border-radius: $sortable-well-border-radius;
      cursor: move;
      display: flex;
      font-weight: $sortable-well-item-font-weight;
      justify-content: space-between;
      margin-bottom: $sortable-well-border-radius;
      opacity: 1;
      padding: $sortable-well-item-padding;

      &-text {
        min-width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &-category {
        background-color: $sortable-well-item-category-bg-colour;
        border-radius: $sortable-well-border-radius;
        color: $sortable-well-item-category-colour;
        font-size: $sortable-well-item-category-font-size;
        font-weight: bold;
        padding: $sortable-well-item-category-padding;
      }

      &-dragging {
        opacity: $sortable-well-item-dragging-opacity;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
