.curated-lists {
  .own-lists, .parent-lists {
    .actions {
      .icon {
        font-size: $font-size-body-standard;
      }
    }
  }

  &-success {
    cursor: pointer;
  }

  &-failed, &-failed>td {
    background-color: $colour-greyish-red !important;
  }
  &-failed:hover, &-failed:hover > td {
    background-color: darken($colour-greyish-red, 5%) !important;
  }

  &-loading, &-loading>td {
    background-color: lightgoldenrodyellow !important;
  }
  &-loading:hover, &-loading:hover > td {
    background-color: darken(lightgoldenrodyellow, 5%) !important;
  }

  &-view {
    margin-top: $spacing-inline-large;

    &-none {
      .actions {
        display: inline-block;
      }
    }

    &-existing {

      .table-th {
        width: 33%;
      }

      .add-existing-list-action {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .actions, .checkbox {
          margin-left: $spacing-inline-large;
        }
        .cvl-type-dropdown {
          width: 50%;
          margin-bottom: 0;
        }
        &__label {
          white-space: nowrap;
        }
      }

      &-added {
        font-style: italic;
      }
    }

    .audit-empty-user-text {
      font-style: italic;
      color: $colour-grey
    }
  }

  &-footer {
    display: flex;
    justify-content: space-between;
  }

  &-export {
    display: inline-block;
  }

  &-fixed-header {
    overflow-y: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    max-height: 100px;
  }

  &-view-edit-icon {
    height: $font-size-body-standard;
    width: $font-size-body-standard;
    position: relative;
    top: 3px;

    &:before {
      content: '';
      display: block;
      height: 100%;
      background: url('/images/view-edit.png') no-repeat;
      background-size: 100%;
    }
  }

  .custom-filter {
    width: 350px;
  }
}
