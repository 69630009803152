@import "../../../../scss/_tools";

.container {
  &__topBorder {
    height: 1px;
    margin-bottom: -1px;
    background: #e2e2e2;
  }

  &__caption {
    color: #121212;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
  }

  &__tooltip {
    max-width: 400px;
    border: 1px solid #e2e2e2;
    border-radius: 3px;
    padding: 20px 10px;
    background: $colour-white;
    box-shadow: 0 4px 10px -6px $container-box-shadow-colour-black;

    th, td {
      padding-bottom: 10px;
      font-size: 15px;
      vertical-align: top;
    }

    tr:last-child {
      th, td {
        padding-bottom: 0;
      }
    }

    td {
      color: #121212;
    }

    th {
      padding-right: 10px;
      color: $colour-dusty-gray;
      text-align: right;
    }
  }

  [class~="recharts-cartesian-axis-ticks"] text {
    fill: #5a5a5a;
    font-weight: bold;
  }

  [class~="recharts-cartesian-axis-line"],
  [class~="recharts-cartesian-axis-tick-line"] {
    stroke: $colour-silver;
  }

  [class~="recharts-cartesian-grid-vertical"] line {
    stroke: #e2e2e2;
  }
}
