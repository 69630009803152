.component {
  max-width: 600px;
  margin: 0 auto;
}

.message {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 10px 15px;

  button {
    width: 100%;
    margin-top: 10px;
    white-space: normal;
  }
}
