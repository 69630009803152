$sortable-well-max-width: ($spacing-small-width * 0.5);
$sortable-well-min-width: ($sortable-well-max-width * 0.5);
$sortable-well-margin: $spacing-small;
$sortable-well-font-size: $font-size-body-regular;
$sortable-well-header-margin-bottom: $spacing-small;
$sortable-well-header-font-size: $font-size-body-medium;
$sortable-well-header-padding-left: 2px;
$sortable-well-border-colour: $colour-grey-400;
$sortable-well-border-radius: $spacing-border-radius-small;
$sortable-well-border-width: $base-border-width;
$sortable-well-container-background-color: $colour-grey-200;
$sortable-well-container-padding: 5px;
$sortable-well-container-active-border-colour: $colour-grey-500;
$sortable-well-item-padding: $spacing-small $spacing-medium;
$sortable-well-item-font-weight: 500;
$sortable-well-item-background-color: $colour-white;
$sortable-well-item-dragging-opacity: 0.2;
$sortable-well-item-category-font-size: $font-size-body-small + 2px;
$sortable-well-item-category-bg-colour: $colour-pizazz;
$sortable-well-item-category-padding: $spacing-inline-small * 0.5
  $spacing-inline-small * 2 0 $spacing-inline-small * 2;
$sortable-well-item-category-colour: $colour-white;
