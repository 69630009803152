.slider {
  border-radius: 6px;
  margin-bottom: 40px;

  &-control {
    &-bottomcenter {
      margin-bottom: -60px;
    }

    &-centerright,
    &-centerleft {
      .fa {
        color: $colour-white;
        cursor: pointer;
        font-size: 40px;
        margin: 0 7px;
        text-shadow: 0 1px 2px $arrows-text-shadow-colour;
      }
    }
  }
}
