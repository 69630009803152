.request-credits-modal {
  .text {
    line-height: $base-line-height;
    padding-bottom: $spacing-medium;
  }

  .form-group {
    align-items: baseline;
    display: flex;
    margin-bottom: 8px;

    .label {
      color: $colour-black;
      flex: 0 0 170px;
      font-size: 100%;
      font-weight: 400;
      text-align: left;
    }

    .control {
      flex: 0 0 100px;

      &.size-l {
        flex: 0 0 200px;
      }
    }
  }
}
