// Approach used from here:
// https://css-tricks.com/five-methods-for-five-star-ratings/
.star-rating {
  --percent: calc(var(--rating) * 100%);

  display: inline-block;
  font-size: 16px;
  font-family: Times; // make sure ★ appears correctly
  line-height: 1;

  &::before {
    // there was issue with star symbol when it's added directly in scss
    // I think it could be that build sometimes doens't recognize it. So adding unicode fixes it
    content: "\2605";
    background: linear-gradient(
      90deg,
      black var(--percent),
      var(--empty-color) var(--percent)
    );

    // The below allows us to clip the gradient
    // to the shape of the stars
    // The only holdout for browser support is IE11,
    // but this is fine as we don't officially support it
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
