// Animation speeds
// $animation-{property}-{descriptor}

// duration
$animation-duration-fast: 0.1s;
$animation-duration-medium: 0.3s;
$animation-duration-slow: 0.5s;

// timing-function
$animation-timing-function-linear: linear;
$animation-timing-function-ease: ease;
$animation-timing-function-easy-in-out: ease-in-out;
