.sequence-runs-uploading-table {
  &--sample-col {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    width: 100%;

    .text {
      align-items: center;
      display: flex;
      height: $font-size-body-medium + 6 * $progress-bar-spacing-inline;
      margin-right: 12px;
    }
  }
}

.rt {
  &-tbody > &-tr-group {
    &:nth-child(odd) > .failures {
      background-color: $sequence-runs-uploading-table-failures-row-colour;

      &:hover {
        background-color: darken(
          $sequence-runs-uploading-table-failures-row-colour,
          5%
        );
      }
    }

    &:nth-child(even) > .failures {
      background-color: darken(
        $sequence-runs-uploading-table-failures-row-colour,
        10%
      );

      &:hover {
        background-color: darken(
          $sequence-runs-uploading-table-failures-row-colour,
          15%
        );
      }
    }
  }
}
