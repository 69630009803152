// This file overwrites the existing sapientia-web css that overwrites bootstrap.
// It's focus is to undo the styling changes that are applied so that bootstrap
// functions as it's supposed to. DO NOT MAKE ANY OTHER CHANGES HERE.

@use "sass:math";

@mixin bootstrap-grid($type: "xs") {
  @for $i from 1 through 12 {
    &.col-#{$type}-#{$i} {
      float: left;
      width: #{math.div(100%, 12) * $i};
      padding: 0 15px;
    }
  }
}

.react-wrapper form.form {
  .form-group {
    display: block;
    float: none;
    width: auto;
    margin-bottom: 15px;
    border-top: 0;

    &:hover {
      background-color: transparent;
    }

    &.row {
        margin: 0 -15px 15px;
    }

    &:nth-child(odd) {
      background: transparent;
    }

    > label {
      font-weight: bold;
      text-align: right;
    }

    > label,
    > div {
      float: none;
      width: auto;
      display: inline-block;
      padding: 7px 15px;
      vertical-align: baseline;

      @include bootstrap-grid("sm");
    }
  }

  &.form:not(.display) .form-group > div {
    padding: 0;

    @include bootstrap-grid("sm");
  }
}
