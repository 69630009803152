.container {
  padding-top: 5px;
  padding-left: 5px;
}

.group {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-left: 5px;
}

.label {
  display: flex;
  font-weight: 700;
}

.value {
  display: flex;
  padding: 5px;
  align-items: center;
  flex-wrap: wrap;
}

.item {
  margin-right: 5px;
  margin-bottom: 5px;
}

.score {
  margin-right: 5px;
}
