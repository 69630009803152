@import "../_tools/variables/general/spacing";
@import "../_tools/variables/general/colours";

.qc {
  &__oncology {
    &__patient-status {
      &__container {
        text-align: right;
      }
    }

    &__actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      &__qc_download {
        margin-right: 12px;
      }

      &__significant-status {
        padding-left: $spacing-small;
      }

      &__status {
        opacity: 1 !important;
        cursor: default !important;
        border: none !important;
      }
    }

    &__json {
      &_warning {
        display: flex;
        flex-direction: row;
        align-items: center;
        &_icon {
          margin-right: $spacing-small;
        }
      }
      &_table {
        &__warning {
          color: $colour-vermilion;
          &__link {
            margin-left: $spacing-x-small;
          }
        }
        &__fixed {
          thead {
            width: 100%;
          }

          tbody {
            display: block;
            max-height: 50vh;
            overflow-y: auto;
          }

          th {
            word-break: break-all;
          }

          td {
            word-break: break-all;
          }

          tr {
            display: table;
            width: 100%;
            box-sizing: border-box;
          }
        }

        th {
          width: 50%;
        }

        td {
          width: 50%;
        }
      }
    }
  }
}

#pass-meter {
  height: 16em;
}

.method.pass h2 {
  color: #3c763d;
}
.method.pass h2:before {
  content: ✓;
}
.method.warn h2 {
  color: #8a6d3b;
}
.method.warn h2:before {
  content: ⚠;
}
.method.fail h2 {
  color: #a94442;
}
.method.fail h2:before {
  content: ✗;
}

.vcf {
  &-summary {
    &-table {
      width: auto !important;
      &-row {
        background: none;
      }
    }
  }
}

.method.pass h2 small:after {
  content: Passed;
}
.method.warn h2 small:after {
  content: Warning;
}
.method.fail h2 small:after {
  content: Failed;
}

div.method svg {
  height: 500px;
  width: 100%;
}
div.method.col-md-6 svg {
  height: 250px;
}
