.users {
  &-container {
    padding-bottom: $spacing-medium;

    .users-add {
      float: right;

      @media (max-width: $screen-xs-max) {
        float: none;
      }
    }
  }

  &-form {
    &-body {
      padding-bottom: 0;

      .tabs-contents {
        padding-bottom: 0;
      }
    }
    &-footer {
      margin: 0 -15px;
    }
    &-editor {
      display: grid;
      grid-template-columns: 150px 1fr;
      &-label {
        grid-column-start: 1;
      }
    }
  }
}
