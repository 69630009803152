.exomiser {
  padding: $spacing-inline-medium;

  &-row {
    display: grid;
    grid-template-columns: 3fr 3fr 5fr;
  }

  &-gap {
    padding-top: $spacing-medium;
  }

  &-gap-below {
    padding-bottom: $spacing-medium;
  }

  &-pane {
    margin: 0 $spacing-inline-medium;

    &-moi {
      display: flex;
      flex-direction: column;
      margin-top: -$spacing-small;  /* bootstrap style override */
    }

    &-data {
      @include bordered-element($colour-grey-300);
      padding: 12px $spacing-medium * 2;
    }
  }

  &-no-data {
    position: relative !important;
    display: inline !important;
    left: 35% !important;
  }

  &-variant {
    display: flex;
    position: relative;
    .variant {
      flex-direction: column;
    }
    .variant-column {
      padding-left: 0;
    }
  }

  .exomiser-no-scores {
    .list-item {
      cursor: default;
    }

    .form-group > label {
      padding-top: $spacing-small;
    }
  }
}
