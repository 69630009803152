.button-dropdown {
  &-group {
    display: inline-block;
    position: relative;
    vertical-align: middle;
  }

  &-toggle {
    &:focus,
    &:active:focus {
      outline: 0;
    }

    .caret {
      margin-left: 6px;
    }
  }

  &-list-item {
    clear: both;
    cursor: pointer;
    display: block;
    font-weight: normal;
    line-height: 1.42857143;
    padding: 3px 15px;
    white-space: nowrap;

    &:hover {
      background-color: $colour-wild-sand;
    }

    .icon {
      padding-right: 5px;
    }
  }
}
