@mixin arrow-right($w, $h) {
  border-bottom: $h solid transparent;
  border-left: $w solid;
  border-top: $h solid transparent;
}

@mixin arrow-down($w, $h) {
  border-left: $w solid transparent;
  border-right: $w solid transparent;
  border-top: $h solid;
}

@mixin bordered-element($border-color) {
  border: 1px solid $border-color;
  border-radius: 3px;
}

@mixin bordered-block {
  padding: $spacing-border-width-xxl;
  border: $spacing-border-width-medium solid $colour-grey-300;
}

@mixin block-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.85);
  z-index: 1049;
  text-align: center;
}