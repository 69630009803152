// Default variables for the table module
// $table-{subclass?}-{element?}-{state?}-{css property}

$table-header-colour: $colour-grey-400;
$table-header-height: 64px;
$table-row-background-colour: $colour-white;

$table-no-data-background-colour: #ccc;
$table-no-data-colour: $colour-white;

$table-sort-border-colour: $colour-chetwode-blue;

$table-loading-background: rgba(255, 255, 255, 0.8);
$table-loading-div-color: rgba(0, 0, 0, 0.6);

