.curated_variants {
  display: flex;
  justify-content: space-evenly;
  z-index: initial;
  flex-wrap: wrap;

  @include generate-pathogenicity-styles();

  .curated-variants-btn {
    margin: $spacing-inline-small 0;
    outline: 0;
  }

  .dropdown-btn-list-item .icon {
    padding-right: $spacing-inline-medium;
  }

  &_list{
    display: flex;
    flex-direction: column;
  }
}
