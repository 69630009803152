@import '../_tools/variables/general/colours';

.settings-variant-panel {
  &__item {
    &--available {
      background-color: $colour-fern !important;
      color: white;
    }
  }
}
