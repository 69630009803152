.header {
  padding: $base-spacing 0;

  > .col {
    align-items: center;
    display: flex;
  }

  &-logo {
    margin-right: $base-spacing;
    // This is set to the current width of the placeholder logos
    // We should probably set this up to work better
    max-width: 140px;
    width: calc(50% - #{$base-spacing * 0.5});

    &:last-child {
      margin-right: 0;
    }

    &-container {
      max-width: 250px;
    }
  }

  &-language {
    min-width: 160px;
  }

  &--centred {
    .header-logo-container {
      flex-direction: row;
      justify-content: center;
      max-width: none;
    }
  }
}
