.patient {
  &__overview {
    &__oncology {
      &_container {
        margin: auto;
        max-height: 100%;
        [class~="pane-row-value"] {
          width: 50%;
        }
      }
    }
  }
}
