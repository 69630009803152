@import "src/scss/_tools/variables/bootstrap-media-queries-vars";

.addMetadataButton {
  @media (max-width: $screen-xs-max) {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &Group {
    float: right;

    @media (max-width: $screen-xs-max) {
      float: none;
    }
  }
}
