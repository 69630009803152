.modal {
  &Dialog {
    max-width: calc(100% - 20px);

    &[class~="size-l"] {
      width: 900px;
    }

    &[class~="size-xl"] {
      width: 1100px;
    }
  }
}

.modalBody {
  padding-left: 0;
  padding-right: 0;

  .wideContentWrapper {
    & > div {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}
